import { ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import { useAuth } from "../../auth/auth-provider";

export const LogoRenderer = (params: ICellRendererParams) => {
  const { me } = useAuth();
  const imageUrl = params.value
    ? `/api/file/${me?.primaryTenantId}/file/${params.value}`
    : null;

  if (!imageUrl) {
    return null;
  }

  return (
    <img
      src={imageUrl}
      style={{
        maxHeight: "32px",
        maxWidth: "50px",
      }}
      alt="logo"
    />
  );
};
