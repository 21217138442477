import React, { useState } from "react";
import { Button, FormGroup, FormLabel, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useCandidates } from "../../hooks/queries/use-candidates";

interface CandidateOption {
  id: string;
  name: string;
  imageId?: string;
  cvFileId?: string;
}

interface CandidateSelectDialogProps {
  show: boolean;
  onApply: (candidateIds: string[]) => void;
  onCancel: () => void;
}

export default function CandidateSelectDialog({
  show,
  onApply,
  onCancel,
}: CandidateSelectDialogProps) {
  const [candidateIds, setCandidateIds] = useState<string[]>([]);
  const { data: candidates = [] } = useCandidates();

  const candidateOptions: CandidateOption[] = candidates.map((candidate) => ({
    id: candidate.id,
    name: candidate.name,
    imageId: candidate.avatarId,
    cvFileId: candidate.cvFileId,
  }));

  const handleApply = () => {
    onApply(candidateIds);
  };

  const handleSelectionChange = (selected: Record<string, any>) => {
    if (!selected || selected.length === 0) {
      setCandidateIds([]);
      return;
    }

    const candidateIds: string[] = [];
    for (let i = 0; i < selected.length; i++) {
      const candidateName = selected[i].name as string;
      const candidateId = selected[i].id as string;
      const candidateImageId = selected[i].imageId as string;
      const candidateCvFileId = selected[i].cvFileId as string;

      candidateIds.push(candidateId);
    }
    setCandidateIds(candidateIds);
  };

  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Lägg till kandidat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <FormLabel>Välj kandidat</FormLabel>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="name"
            multiple
            options={candidateOptions}
            newSelectionPrefix="Kandidat "
            placeholder="Kandidat.."
            onChange={handleSelectionChange}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <Button variant="secondary" onClick={onCancel}>
          Avbryt
        </Button>
        <Button variant="primary" onClick={handleApply}>
          Lägg till
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
