import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Candidate } from "../../types/candidate";
import { useApiClient } from "../useApiClient";

export const candidateKeys = {
  all: ["candidates"] as const,
  lists: () => [...candidateKeys.all, "list"] as const,
  list: (filters: string) => [...candidateKeys.lists(), { filters }] as const,
  details: () => [...candidateKeys.all, "detail"] as const,
  detail: (id: string) => [...candidateKeys.details(), id] as const,
};

export const useCandidates = () => {
  const { candidatesService } = useApiClient();

  return useQuery({
    queryKey: candidateKeys.lists(),
    queryFn: () => candidatesService.getCandidates(),
  });
};

export const useCandidate = (id: string) => {
  const { candidatesService } = useApiClient();

  return useQuery({
    queryKey: candidateKeys.detail(id),
    queryFn: () => candidatesService.getCandidate(id),
    enabled: !!id,
  });
};

export const useCreateCandidate = () => {
  const queryClient = useQueryClient();
  const { candidatesService } = useApiClient();

  return useMutation({
    mutationFn: (candidate: Candidate) =>
      candidatesService.createCandidate(candidate),
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};

export const useUpdateCandidate = () => {
  const queryClient = useQueryClient();
  const { candidatesService } = useApiClient();

  return useMutation({
    mutationFn: ({
      original,
      edited,
    }: {
      original: Candidate;
      edited: Candidate;
    }) => candidatesService.updateCandidate(original, edited),
    onSuccess: (_, { edited }) => {
      queryClient.invalidateQueries();
    },
  });
};

export const useDeleteCandidate = () => {
  const queryClient = useQueryClient();
  const { candidatesService } = useApiClient();

  return useMutation({
    mutationFn: (id: string) => candidatesService.deleteCandidate(id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries();
    },
  });
};
