import { FileInfo, LinkInfo } from './common'
import { User } from './user'

export type FormField = {
  type: 'Text' | 'RichText' | 'Radio' | 'Checkbox' | 'Select'
  question: string
  description: string
  options: string[]
  values: string[]
}

export type InfoNode = {
  id?: string
  infoHubId?: string
  type: 'RichText' | 'Media' | 'Form'
  title: string
  text?: string
  formFields?: FormField[]
  files?: FileInfo[]
  links?: LinkInfo[]
  suggestAction?: 'FORMAL_REQUIREMENTS' | undefined
}

export type InfoHub = {
  id?: string
  imageId?: string
  infoNodes?: InfoNode[]
  type: 'Candidate' | 'Project'
}

export enum CandidateStatus {
  Applied = 'Applied',
  InterviewByRecruiter = 'InterviewByRecruiter',
  PresentToClient = 'PresentToClient',
  InterviewByClient = 'InterviewByClient',
  Tests = 'Tests',
  FinalInterview = 'FinalInterview',
  Reference = 'Reference',
  OfferPresented = 'OfferPresented',
  Rejected = 'Rejected',
  Declined = 'Declined',
  Hired = 'Hired',
}

export type CandidateInfoHub = InfoHub & {
  candidateName: string
  candidateId: string
  candidatetagLine: string
  status: CandidateStatus
  candidateRecruitmentWorkflowId: string
}

export type ProjectInfoHub = InfoHub & {
  companyName: string
  companyId: string
  projectTitle: string
}

export type Project = {
  id?: string
  title: string
  backgroundImageId?: string
  position: string // role?
  companyName: string
  companyId: string
  mainContact?: User
  mainContactUserId: string
  recruiter?: User
  recruiterUserId?: string
  coRecruiterUserIds?: string[]
  coRecruiters?: User[]
  companyContactName?: string
  companyContactEmail?: string
  companyContactPhone?: string
  desiredStartDate?: string
  tags?: any[]
  status?: string
  created?: Date
  updated?: Date
  projectInfoHub?: ProjectInfoHub | undefined
  candidateInfoHubs?: CandidateInfoHub[] | undefined
  contacts?: User[]
  contactUserIds?: string[]
  recruitmentProcessWorkflowId?: string
}

/** Represents the form data when creating a new project  */
export type CreateProjectAction = {
  title: string
  position: string // role?
  companyName: string
  companyId: string
  mainContactUserId: string
  recruiterUserId?: string
  coRecruiters?: string[]
  companyContactName?: string
  companyContactEmail?: string
  companyContactPhone?: string
  desiredStartDate?: string
  tags?: string[]
  template?: string
  candidateIds?: string[]
}

export type NotifyProjectAction = {
  projectId: string
  sendToMainContact: boolean
  sendToContacts: boolean
  sendToCoRecruiters: boolean
  message?: string
}
