import { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import Dropzone from 'react-dropzone'
import { Document, Page, pdfjs } from 'react-pdf'
import { useAuth } from '../../auth/auth-provider'
import {
  useCandidate,
  useCreateCandidate,
  useUpdateCandidate,
} from '../../hooks/queries/use-candidates'
import { useUploadFile } from '../../hooks/queries/use-files'
import { Candidate } from '../../types/candidate'
import { TaskStatus } from '../../types/workflow'
import ProcessSteps from '../ProcessSteps'

import './CandidateEditor.css'

export default function CandidateEditor({
  id,
  onClose,
}: {
  id?: string
  onClose?: () => void
}) {
  const AVATAR_MISSING = '/projectfiles/avatar-missing.png'
  const CV_MISSING = '/projectfiles/cv-missing.png'
  const CV_EXISTS = '/projectfiles/cv-exists.png'

  const { me } = useAuth()

  const [edited, setEdited] = useState<Candidate>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showCvViewer, setShowCvViewer] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  // Queries and Mutations
  const original = useCandidate(id || '')
  const updateCandidate = useUpdateCandidate()
  const createCandidate = useCreateCandidate()
  const uploadFile = useUploadFile()

  useEffect(() => {
    if (original.data) {
      setEdited(original.data)
    }
  }, [original.data])

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  const handleUploadFile = (file: File, type: 'avatar' | 'cv') => {
    setShowLoading(true)
    uploadFile.mutate(
      { file, fileType: type },
      {
        onSuccess: (data) => {
          const imageId = data[0].id
          setEdited({ ...edited!, avatarId: imageId })
          setShowLoading(false)
        },
        onError: () => {
          setShowLoading(false)
          alert('Error uploading file')
        },
      }
    )
  }

  const handleSave = async () => {
    if (original.isSuccess && original.data) {
      updateCandidate.mutate(
        { original: original.data, edited: edited as Candidate },
        {
          onSuccess: () => {
            setErrorMessage('')
            onClose && onClose()
          },
          onError: (error) => {
            setErrorMessage('Fel: ' + error)
          },
        }
      )
    } else {
      createCandidate.mutate(edited as Candidate, {
        onSuccess: (data) => {
          id = data.id
          setErrorMessage('')
          onClose && onClose()
        },
        onError: (error) => {
          setErrorMessage('Fel: ' + error)
        },
      })
    }
  }

  const handleShowViewer = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setShowCvViewer(true)
  }

  const handleDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const response = await fetch(
      `/api/file/${me?.primaryTenantId}/file/` + edited?.cvFileId
    )
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'cv.pdf'
    link.click()
    window.URL.revokeObjectURL(url)
  }

  if (showLoading) return <div>Loading...</div>

  return (
    <>
      <Form className="row g-3 form-tight">
        <Col md="12" className="d-inline-flex">
          <div>
            <Dropzone
              onDropAccepted={(files) => handleUploadFile(files[0], 'avatar')}
              accept={{
                'image/jpeg': ['.jpg', 'jpeg'],
                'image/png': ['.png', 'PNG'],
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} className="dropzone-container">
                    <input {...getInputProps()} />
                    <img
                      src={
                        edited?.avatarId
                          ? `/api/file/${me?.primaryTenantId}/file/` +
                            edited.avatarId
                          : AVATAR_MISSING
                      }
                      alt={
                        edited?.name
                          ? `${edited.name}'s avatar`
                          : 'Default avatar'
                      }
                      className="rounded"
                    />
                    {showLoading && (
                      <div className="uploading-text">Uploading..</div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            <div className="d-flex">
              <small className="ms-2 text-secondary">Photo</small>
              {edited?.avatarId && (
                <button className="ms-auto btn btn-link p-0">
                  <i
                    className="bi bi-trash"
                    onClick={() => setEdited({ ...edited!, avatarId: '' })}
                  ></i>
                </button>
              )}
            </div>
          </div>
          <div className="ms-2">
            <Dropzone
              onDropAccepted={(files) => handleUploadFile(files[0], 'cv')}
              accept={{
                'application/pdf': ['.pdf'],
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} className="dropzone-container">
                    <input {...getInputProps()} />
                    <img
                      src={edited?.cvFileId ? CV_EXISTS : CV_MISSING}
                      alt={edited?.cvFileId ? 'CV uploaded' : 'No CV uploaded'}
                      className="rounded"
                    />
                    {showLoading && (
                      <div className="uploading-text">Uploading..</div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
            <div className="d-flex">
              <small className="ms-2 text-secondary">CV</small>
              {edited?.cvFileId && (
                <button
                  onClick={handleDownload}
                  className="ms-2 btn btn-link p-0"
                >
                  <i className="bi bi-download"></i>
                </button>
              )}
              {edited?.cvFileId && (
                <button className="ms-2 btn btn-link p-0">
                  <i
                    className="bi bi-trash"
                    onClick={() => setEdited({ ...edited!, cvFileId: '' })}
                  ></i>
                </button>
              )}
              {edited?.cvFileId && (
                <button className="ms-2 btn btn-link p-0">
                  <i className="bi bi-eye" onClick={handleShowViewer}></i>
                </button>
              )}
            </div>
          </div>
          <FormGroup className="flex-fill ms-4">
            <Form.Label>Sammanfattning</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={edited?.summary ?? ''}
              onChange={(e) => {
                setEdited({ ...edited!, summary: e.target.value })
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Namn</Form.Label>
            <Form.Control
              value={edited?.name ?? ''}
              placeholder="name"
              onChange={(e) => {
                setEdited({ ...edited!, name: e.target.value })
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>E-post</Form.Label>
            <InputGroup>
              <Form.Control
                value={edited?.email ?? ''}
                placeholder=""
                type="email"
                onChange={(e) => {
                  setEdited({ ...edited!, email: e.target.value })
                }}
              />
              <Button
                href={'mailto:' + edited?.email}
                target="#"
                disabled={!edited?.email}
              >
                <i className="bi bi-envelope"></i>
              </Button>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Telefon</Form.Label>
            <InputGroup>
              <Form.Control
                value={edited?.phoneNumber ?? ''}
                placeholder=""
                type="phone"
                onChange={(e) => {
                  setEdited({ ...edited!, phoneNumber: e.target.value })
                }}
              />
              <Button href={'tel:' + edited?.phoneNumber} target="#">
                <i className="bi bi-telephone-outbound"></i>
              </Button>
            </InputGroup>
          </FormGroup>
        </Col>

        <Col md="4">
          <FormGroup>
            <Form.Label>Löneanspråk</Form.Label>
            <Form.Control
              placeholder=""
              value={edited?.expectedSalary ?? ''}
              onChange={(e) => {
                setEdited({ ...edited!, expectedSalary: e.target.value })
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Uppsägningstid</Form.Label>
            <Form.Control
              placeholder=""
              value={edited?.noticePeriod ?? ''}
              onChange={(e) => {
                setEdited({ ...edited!, noticePeriod: e.target.value })
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="8">
          <FormGroup>
            <Form.Label>LinkedIn-länk</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder=""
                type="url"
                value={edited?.linkedInUrl ?? ''}
                onChange={(e) => {
                  setEdited({ ...edited!, linkedInUrl: e.target.value })
                }}
              ></Form.Control>

              <Button
                href={edited?.linkedInUrl ?? ''}
                target="blank_"
                disabled={!edited?.linkedInUrl}
              >
                <i className="bi bi-box-arrow-up-right"></i>
              </Button>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Form.Label>Taggar</Form.Label>
            <Typeahead
              id="basic-typeahead-multiple"
              labelKey="name"
              multiple
              allowNew
              options={[]}
              newSelectionPrefix="Lägg till ny tagg: "
              placeholder="Tag..."
              onChange={(e) => {
                setEdited({ ...edited!, tags: e as string[] })
              }}
              selected={edited?.tags ?? []}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Form.Label>Skills</Form.Label>
            <Typeahead
              id="basic-typeahead-multiple"
              labelKey="name"
              multiple
              allowNew
              options={[]}
              newSelectionPrefix="Lägg till ny skill: "
              placeholder="Skill.."
              onChange={(e) => {
                setEdited({ ...edited!, skills: e as string[] })
              }}
              selected={edited?.skills ?? []}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Referens</Form.Label>
            <Form.Control
              placeholder=""
              value={edited?.referencePersonName ?? ''}
              onChange={(e) => {
                setEdited({ ...edited!, referencePersonName: e.target.value })
              }}
            ></Form.Control>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Referens epost</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder=""
                value={edited?.referencePersonEmail ?? ''}
                onChange={(e) => {
                  setEdited({
                    ...edited!,
                    referencePersonEmail: e.target.value,
                  })
                }}
              ></Form.Control>
              <Button
                href={'mailto:' + edited?.referencePersonEmail}
                target="#"
                disabled={!edited?.referencePersonEmail}
              >
                <i className="bi bi-envelope"></i>
              </Button>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Form.Label>Referens telefon</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder=""
                value={edited?.referencePersonPhone ?? ''}
                onChange={(e) => {
                  setEdited({
                    ...edited!,
                    referencePersonPhone: e.target.value,
                  })
                }}
              ></Form.Control>
              <Button href={'tel:' + edited?.referencePersonPhone} target="#">
                <i className="bi bi-telephone-outbound"></i>
              </Button>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Form.Label>Referenstagning, status</Form.Label>
            <ProcessSteps
              steps={[
                {
                  taskId: '1',
                  title: 'Referens angiven',
                  status: TaskStatus.Done,
                  description: 'Kandidaten har angivit en referensperson',
                },
                {
                  taskId: '2',
                  title: 'Inbjudan skickad',
                  status: TaskStatus.Done,
                  description: 'Inbjudan har skickats till referenspersonen',
                },
                {
                  taskId: '3',
                  title: 'Klar',
                  status: TaskStatus.ToDo,
                  description: 'Inbjudan har skickats till referenspersonen',
                },
              ]}
            ></ProcessSteps>
          </FormGroup>
        </Col>
      </Form>

      {errorMessage && (
        <Alert variant="danger" className="mt-2">
          {errorMessage}
        </Alert>
      )}

      <div className="mt-2 gm-2 d-flex justify-content-end">
        <Button onClick={() => setEdited(original.data)}>Ångra</Button>
        <Button className="ms-2" onClick={handleSave}>
          Spara
        </Button>
      </div>

      <Modal
        show={showCvViewer}
        animation
        size="lg"
        onHide={() => setShowCvViewer(false)}
        scrollable
        backdrop
      >
        <ModalHeader closeButton>CV</ModalHeader>
        <ModalBody>
          <Document
            file={`/api/file/${me?.primaryTenantId}/file/` + edited?.cvFileId}
            className="pdf_document ms-auto me-auto"
          >
            <Page
              pageNumber={1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
        </ModalBody>
      </Modal>
    </>
  )
}
