import {
  Edge,
  Node,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Modal,
} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import CandidateSelectDialog from '../components/candidate/CandidateSelectDialog'
import AnnotationNode from '../components/mindmap/AnnotationNode'
import CandidateNode from '../components/mindmap/CandidateNode'
import InformationNode from '../components/mindmap/InfoNode'
import ProjectNode from '../components/mindmap/ProjectNode'
import SubTitleNode from '../components/mindmap/SubTitleNode'
import TitleNode from '../components/mindmap/TitleNode'
import CandidateInfoHubEditor from '../components/project/CandidateInfoHubEditor'
import InfoNodeEditor from '../components/project/InfoNodeEditor'
import ProjectEditorDialog from '../components/project/ProjectEditorDialog'
import ProjectInfoHubEditor from '../components/project/ProjectInfoHubEditor'
import {
  useAddCandidatesToProject,
  useCreateInfoNode,
  useDeleteInfoHub,
  useDeleteInfoNode,
  useProject,
  useProjectMindmap,
  useSendNotifications,
} from '../hooks/queries/use-projects'
import {
  MindmapProvider,
  useMindmapContext,
} from '../providers/mindmap-provider'
import { InfoNode } from '../types/project'
import {
  createEdgesForProject,
  createNodesForProject,
} from '../utils/mindmap-utils'
import './ProjectPageNew.css'
import NotificationModal from '../components/project/NotificationModal'
import ControlPanel from '../components/project/ControlPanel'
import { useWorkflow } from '../hooks/queries/use-workflows'

const nodeTypes = {
  candidate: CandidateNode,
  info: InformationNode,
  title: TitleNode,
  subtitle: SubTitleNode,
  project: ProjectNode,
  annotation: AnnotationNode,
}

const Flow = () => {
  // Params
  const { id } = useParams()

  // Mindmap context
  const { readOnly, forcedReadOnly, setForcedReadOnly } = useMindmapContext()

  // React flow
  const { setCenter, fitView } = useReactFlow()

  // React Query hooks
  const { data: project } = useProjectMindmap(id || '')
  const workflow = useWorkflow(project?.recruitmentProcessWorkflowId || '')
  const deleteInfoNodeMutation = useDeleteInfoNode()
  const deleteInfoHubMutation = useDeleteInfoHub()
  const createInfoNodeMutation = useCreateInfoNode()
  const addCandidatesMutation = useAddCandidatesToProject()

  // React flow state
  const initialNodeList: Node[] = []
  const initialEdgeList: Edge[] = []
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodeList)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdgeList)

  // UI state
  const [isInitialized, setIsInitialized] = useState(false)
  const [selectedInfoNodeId, setSelectedInfoNodeId] = useState<string>()
  const [selectedProjectInfoHubId, setSelectedProjectInfoHubId] =
    useState<string>()
  const [selectedCandidateInfoHubId, setSelectedCandidateInfoHubId] =
    useState<string>()
  const [sidePanelExpanded, setSidePanelExpanded] = useState(false)
  const [showAddNodeDialog, setShowAddNodeDialog] = useState(false)
  const [newNodeType, setNewNodeType] = useState<'RichText' | 'Media'>(
    'RichText'
  )
  const [newNodeTitle, setNewNodeTitle] = useState<string>('Ny')
  const [showAddCandidateDialog, setShowAddCandidateDialog] = useState(false)
  const [showProjectEditorDialog, setShowProjectEditorDialog] = useState(false)

  // Effect to update nodes and edges when project data changes
  useEffect(() => {
    if (!project || !workflow.data) return

    const projectNodeId = project.projectInfoHub?.id ?? 'project-node'
    setNodes(createNodesForProject(project, workflow.data))
    setEdges(createEdgesForProject(project, projectNodeId))
  }, [project, setEdges, setNodes, workflow.data])

  // Effect to fit view when initialized
  useEffect(() => {
    if (isInitialized) return

    setIsInitialized(true)
    setTimeout(() => {
      fitView({ duration: 800 })
    }, 100)
  }, [fitView, isInitialized])

  const handleSidePanelClose = () => {
    setSelectedInfoNodeId(undefined)
    setSelectedCandidateInfoHubId(undefined)
    setSelectedProjectInfoHubId(undefined)
  }

  const handleSidePanelExpand = () => {
    setSidePanelExpanded(!sidePanelExpanded)
  }

  const onNodeClick = (event: React.MouseEvent, node: Node) => {
    const sidebarWidth = 614
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    const availableWidth = windowWidth - sidebarWidth
    const hubSize = 120
    const xOffset =
      sidebarWidth + availableWidth / 2 - windowWidth / 2 - hubSize
    const yOffset = windowHeight * 0.15

    if (node.type === 'info' && node.parentId) {
      const parentNode = nodes.find((n) => n.id === node.parentId)
      if (parentNode) {
        setCenter(parentNode.position.x - xOffset, parentNode.position.y, {
          duration: 800,
          zoom: 1.5,
        })
      }
    } else if (node.type === 'project' || node.type === 'candidate') {
      const positionY =
        node.type === 'project'
          ? node.position.y + yOffset
          : node.position.y - yOffset

      setCenter(node.position.x - xOffset, positionY, {
        duration: 800,
        zoom: 1.5,
      })
    }

    if (node.type === 'info') {
      setSelectedInfoNodeId(node.id)
      setSelectedCandidateInfoHubId(undefined)
      setSelectedProjectInfoHubId(undefined)
    } else if (node.type === 'project') {
      setSelectedProjectInfoHubId(node.id)
      setSelectedCandidateInfoHubId(undefined)
      setSelectedInfoNodeId(undefined)
    } else if (node.type === 'candidate') {
      setSelectedCandidateInfoHubId(node.id)
      setSelectedProjectInfoHubId(undefined)
      setSelectedInfoNodeId(undefined)
    }
  }

  const handleDelete = async () => {
    if (selectedInfoNodeId) {
      try {
        await deleteInfoNodeMutation.mutateAsync(selectedInfoNodeId)
        setSelectedInfoNodeId(undefined)
      } catch (error) {
        alert('Error deleting infoNode: ' + error)
      }
    }
    if (selectedCandidateInfoHubId) {
      try {
        await deleteInfoHubMutation.mutateAsync(selectedCandidateInfoHubId)
        setSelectedCandidateInfoHubId(undefined)
      } catch (error) {
        alert('Error deleting candidateInfoHub: ' + error)
      }
    }
  }

  const applyNewNode = async () => {
    setShowAddNodeDialog(false)
    const infoHubId = selectedProjectInfoHubId ?? selectedCandidateInfoHubId
    if (!infoHubId) return

    const newInfoNode: InfoNode = {
      title: newNodeTitle,
      type: newNodeType,
      infoHubId: infoHubId,
    }

    try {
      await createInfoNodeMutation.mutateAsync({
        hubId: infoHubId,
        node: newInfoNode,
      })
    } catch (error) {
      alert('Error creating info node: ' + error)
    }
  }

  const handleAddCandidates = async (candidateIds: string[]) => {
    if (!project) return

    try {
      await addCandidatesMutation.mutateAsync({
        projectId: project.id ?? '',
        candidateIds,
      })
    } catch (error) {
      alert('Error adding candidates to project: ' + error)
    }
  }

  return !project ? null : (
    <div className="project-page-container">
      <aside
        className={
          'project-side-panel ' +
          (selectedInfoNodeId ||
          selectedCandidateInfoHubId ||
          selectedProjectInfoHubId
            ? ' open '
            : '') +
          (sidePanelExpanded ? ' expanded ' : '')
        }
      >
        <div className="project-side-panel-toolbar">
          <button
            className="symbol expand"
            onClick={handleSidePanelExpand}
          ></button>
          <button
            className="symbol close"
            onClick={handleSidePanelClose}
          ></button>
        </div>
        <div className="project-side-panel-content">
          {selectedInfoNodeId && (
            <InfoNodeEditor
              key={selectedInfoNodeId}
              infoNodeId={selectedInfoNodeId}
              project={project}
            />
          )}
          {selectedProjectInfoHubId && (
            <>
              <ProjectInfoHubEditor
                key={selectedProjectInfoHubId}
                projectInfoHubId={selectedProjectInfoHubId}
                project={project}
                infoHubChanged={() => {}}
                setShowAddNodeDialog={setShowAddNodeDialog}
              />
            </>
          )}
          {selectedCandidateInfoHubId && (
            <>
              <CandidateInfoHubEditor
                key={selectedCandidateInfoHubId}
                candidateInfoHubId={selectedCandidateInfoHubId}
                project={project}
                infoHubChanged={() => {}}
                setShowAddNodeDialog={setShowAddNodeDialog}
              />
            </>
          )}
          <hr />

          {!forcedReadOnly && (
            <>
              <div className="d-flex align-items-center mt-4 justify-content-between">
                <div className="d-flex">
                  <Button variant="primary" onClick={handleDelete}>
                    <i className="bi bi-trash"></i>
                  </Button>

                  <Button
                    variant="primary"
                    hidden={!selectedProjectInfoHubId}
                    onClick={() => setShowAddCandidateDialog(true)}
                    className="ms-2"
                  >
                    <i className="bi bi-plus"></i> Lägg till Kandidat
                  </Button>
                </div>
                <div>
                  <Button
                    variant="primary"
                    hidden={!selectedProjectInfoHubId}
                    onClick={() => setShowProjectEditorDialog(true)}
                    className="ms-2"
                  >
                    <i className="bi bi-pencil-square"></i> Redigera projekt
                  </Button>
                  <ProjectEditorDialog
                    show={showProjectEditorDialog}
                    companyId={project.companyId}
                    projectId={project.id}
                    onCancel={() => setShowProjectEditorDialog(false)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </aside>

      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        colorMode="dark"
        defaultViewport={{ x: 0, y: 0, zoom: 1 }}
        fitView
        minZoom={0.5}
        className="react-flow-subflows-example"
        maxZoom={2}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        draggable={true}
        onNodeClick={onNodeClick}
        onPaneClick={() => {
          setSelectedInfoNodeId(undefined)
          setSelectedCandidateInfoHubId(undefined)
          setSelectedProjectInfoHubId(undefined)

          setNodes((nodes) =>
            nodes.map((node) => ({ ...node, selected: false }))
          )
        }}
      >
        {!readOnly && <ControlPanel projectId={project.id} />}
      </ReactFlow>

      {/* Add your Modals here */}
      <Modal
        show={showAddNodeDialog}
        onHide={() => setShowAddNodeDialog(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Lägg till info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Välj typ</FormLabel>
            <FormSelect
              onChange={(e) =>
                setNewNodeType(e.target.value as 'Media' | 'RichText')
              }
            >
              <option value="RichText">Text</option>
              <option value="Media">Media</option>
            </FormSelect>
          </FormGroup>
          <FormGroup>
            <FormLabel>Titel</FormLabel>
            <FormControl
              onChange={(e) => setNewNodeTitle(e.target.value)}
              value={newNodeTitle}
            ></FormControl>
          </FormGroup>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={applyNewNode}>
            Lägg till
          </Button>
        </Modal.Footer>
      </Modal>

      <CandidateSelectDialog
        show={showAddCandidateDialog}
        onApply={handleAddCandidates}
        onCancel={() => setShowAddCandidateDialog(false)}
      />
    </div>
  )
}

export const ProjectPageNew = () => {
  return (
    <MindmapProvider>
      <ReactFlowProvider>
        <Flow />
      </ReactFlowProvider>
    </MindmapProvider>
  )
}
