import { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Form, FormGroup } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useAuth } from '../../auth/auth-provider'
import { useCandidates } from '../../hooks/queries/use-candidates'
import { useCompanies, useCompany } from '../../hooks/queries/use-companies'
import { useCreateProject } from '../../hooks/queries/use-projects'
import { CreateProjectAction } from '../../types/project'
import { User } from '../../types/user'
import { Company } from '../../types/company'

export default function NewProjectForm({ onSave }: { onSave: () => void }) {
  const { user, me } = useAuth()
  const candidates = useCandidates()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [companyAbout, setCompanyAbout] = useState<string>('')
  const [companyId, setCompanyId] = useState<string>('')
  const [page, setPage] = useState(0)

  const [formData, setFormData] = useState<CreateProjectAction>({
    title: '',
    position: '',
    companyName: '',
    companyId: '',
    recruiterUserId: '',
    mainContactUserId: '',
    desiredStartDate: 'ASAP',
    coRecruiters: [],
    template: 'standard',
  })

  useEffect(() => {
    if (!user) return

    setFormData((prev) => ({
      ...prev,
      recruiterUserId: me?.id,
    }))
  }, [user, me])

  const formTitles = ['Projekt', 'Kandidater']
  const createProject = useCreateProject()

  const companies = useCompanies()
  const company = useCompany(companyId)

  const contactOptions = useMemo(() => {
    if (!company.data) return []
    return company.data.contacts
  }, [company.data])

  const submit = () => {
    createProject.mutate(formData, {
      onSuccess: (response) => {
        setErrorMessage('')
        onSave()
      },
      onError: (error) => {
        setErrorMessage('Error creating project: ' + error)
      },
    })
  }

  const headerForm = () => {
    return (
      <div>
        <Form className="form-tight">
          <FormGroup className="col-md-12">
            <Form.Label>Roll</Form.Label>
            <Form.Control
              type="text"
              value={formData?.position}
              onChange={(e) =>
                setFormData({ ...formData, position: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup className="col-md-12">
            <Form.Label>Företag</Form.Label>
            <Typeahead
              id="basic-typeahead-multiple"
              labelKey="name"
              allowNew
              options={companies.data ?? []}
              newSelectionPrefix="Företag "
              placeholder="Företag.."
              onChange={(e: Record<string, any>) => {
                if (!e) return
                const companyName = e[0].name as string
                const companyId = e[0].id as string
                const companyAvatarId = e[0].imageId as string
                const companyAbout = e[0].about as string
                setCompanyAbout(companyAbout)
                setCompanyId(companyId)

                const suggestedTitle =
                  formData.title === ''
                    ? formData.position + ' till ' + companyName
                    : formData.title
                setFormData({
                  ...formData,
                  companyName: companyName,
                  companyId: companyId,
                  title: suggestedTitle,
                })
              }}
              selected={formData.companyName ? [formData.companyName] : []}
              style={{ zIndex: 9999 }}
            />
          </FormGroup>
          <FormGroup className="col-md-12">
            <Form.Label>Projekt-namn</Form.Label>
            <Form.Control
              type="text"
              value={formData?.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Form.Label>Projekt-mall</Form.Label>
            <Form.Select
              onChange={(e) =>
                setFormData({ ...formData, template: e.target.value })
              }
            >
              <option value={''}>Välj mall..</option>
              <option value={'standard'}>Standard</option>
              <option value={'minimal'}>Minimialistisk</option>
              <option value={'manager'} disabled>
                Chefsposition
              </option>
              <option value={'creative'} disabled>
                Kreativa yrken med arbetsprover
              </option>
            </Form.Select>
          </FormGroup>
        </Form>
      </div>
    )
  }

  const candidateForm = () => {
    return (
      <div>
        <Form className="form-tight">
          <FormGroup className="col-md-4">
            <Form.Label>Kandidat</Form.Label>

            <Typeahead
              id="basic-typeahead-multiple"
              labelKey="name"
              multiple
              options={candidates.data ?? []}
              newSelectionPrefix="Kandidat "
              placeholder="Kandidat.."
              onChange={(e: Record<string, any>) => {
                if (!e) return
                if (e.length === 0) return
                const candidateIds: string[] = []
                for (let i = 0; i < e.length; i++) {
                  candidateIds.push(e[i].id as string)
                }
                setFormData({
                  ...formData,
                  candidateIds: candidateIds,
                })
              }}
            />
          </FormGroup>
          <FormGroup className="col-8">
            <Form.Label>Huvudkontaktperson hos kund</Form.Label>
            <Typeahead
              key={companyId}
              id="project-main-contact-selector-typeahead"
              labelKey={(option) => {
                const user = option as User
                return `${user.firstName} ${user.lastName}`
              }}
              options={contactOptions}
              onChange={(selected) => {
                const user = selected[0] as User
                if (!user) {
                  return setFormData({
                    ...formData,
                    mainContactUserId: '',
                  })
                }
                return setFormData({
                  ...formData,
                  mainContactUserId: (selected[0] as User).id,
                })
              }}
            />
          </FormGroup>
        </Form>
      </div>
    )
  }

  const formDisplay = () => {
    if (page === 0) {
      return headerForm()
    } else if (page === 1) {
      return candidateForm()
    } else return <div>Page {page}</div>
  }
  return (
    <div>
      <h4>{formTitles[page]}</h4>
      {formDisplay()}
      {errorMessage && (
        <Alert variant="danger" className="mt-2">
          {errorMessage}
        </Alert>
      )}
      <div className="d-flex mt-4 justify-content-between gap-2">
        <Button
          disabled={page === 0}
          onClick={(e) => setPage(page - 1)}
          variant="secondary"
        >
          Tillbaka
        </Button>
        <div className="d-flex justify-content-end">
          <Button
            hidden={page === formTitles.length - 1}
            onClick={(e) => setPage(page + 1)}
            variant="primary"
          >
            Nästa
          </Button>
          <Button
            onClick={(e) => submit()}
            variant="primary"
            className="ms-2"
            disabled={
              !(
                formData.companyName.length > 0 &&
                formData.position.length > 0 &&
                page === formTitles.length - 1
              )
            }
          >
            Skapa
          </Button>
        </div>
      </div>
    </div>
  )
}
