import React, { memo } from "react";
import { Handle, Position } from "@xyflow/react";

export default memo(({ data }: { data: { label: string } }) => {
  return (
    <>
      <div>
        <div>{data.label}</div>
      </div>
    </>
  );
});
