import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { Company } from '../../types/company'
import { useApiClient } from '../useApiClient'

export const companyKeys = {
  all: ['companies'] as const,
  lists: () => [...companyKeys.all, 'list'] as const,
  list: (filters: string) => [...companyKeys.lists(), { filters }] as const,
  details: () => [...companyKeys.all, 'detail'] as const,
  detail: (id: string) => [...companyKeys.details(), id] as const,
}

export const useCompanies = () => {
  const { companiesService } = useApiClient()

  return useQuery({
    queryKey: companyKeys.lists(),
    queryFn: () => companiesService.getCompanies(),
  })
}

export const useCompany = (id: string) => {
  const { companiesService } = useApiClient()

  return useQuery({
    queryKey: companyKeys.detail(id),
    queryFn: () => companiesService.getCompany(id),
    enabled: !!id,
  })
}

export const useCreateCompany = () => {
  const queryClient = useQueryClient()
  const { companiesService } = useApiClient()

  return useMutation({
    mutationFn: (company: Company) => companiesService.createCompany(company),
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}

export const useUpdateCompany = () => {
  const queryClient = useQueryClient()
  const { companiesService } = useApiClient()

  return useMutation({
    mutationFn: ({
      original,
      edited,
    }: {
      original: Company
      edited: Company
    }) => companiesService.updateCompany(original, edited),
    onSuccess: (_, { edited }) => {
      queryClient.invalidateQueries()
    },
  })
}

export const useDeleteCompany = () => {
  const queryClient = useQueryClient()
  const { companiesService } = useApiClient()

  return useMutation({
    mutationFn: (id: string) => companiesService.deleteCompany(id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries()
    },
  })
}
