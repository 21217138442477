import React, { memo } from "react";
import { Handle, Position } from "@xyflow/react";

export default memo(
  ({
    data,
  }: {
    data: { label: string; level: number; arrow: string; arrowStyle: any };
  }) => {
    return (
      <>
        <div>
          <div>{data.label}</div>
        </div>
      </>
    );
  }
);
/* 
export function AnnotationNode({ data }: NodeProps<AnnotationNode>) {
  return (
    <div className="relative flex max-w-[180px] items-start p-2 text-sm text-secondary-foreground">
      {" "}
      {typeof data.level === "number" && (
        <div className="mr-1 leading-snug">{data.level}.</div>
      )}{" "}
      <div className="leading-snug">{data.label}</div>{" "}
      {data.arrowStyle && (
        <div className="absolute text-2xl" style={data.arrowStyle}>
          {" "}
          {data.arrow}{" "}
        </div>
      )}{" "}
    </div>
  );
} */
