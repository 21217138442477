import { Table } from "react-bootstrap"
import { useAuth } from "../auth/auth-provider"

export const Admin = () => {
  const { user } = useAuth()
  return (
    <div className='container py-5'>
      <p>Admin goes here. Only available to ADMINS</p>
      <Table>
        <tbody>
          <tr>
            <td>Scopes</td>
            <td>{user?.scopes ?? "No scopes"}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
