import { useState } from 'react'
import { Button } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { useAuth } from '../../auth/auth-provider'
import { useUploadFile } from '../../hooks/queries/use-files'
import {
  useProjectInfoHub,
  useUpdateProjectInfoHubImage,
} from '../../hooks/queries/use-projects'
import { useMindmapContext } from '../../providers/mindmap-provider'
import { Project, ProjectInfoHub } from '../../types/project'

import './ProjectInfoHubEditor.css'

interface ProjectInfoHubEditorProps {
  projectInfoHubId: string
  project: Project
  infoHubChanged: (infoHub: ProjectInfoHub) => void
  setShowAddNodeDialog: (show: boolean) => void
}

const MAX_NODES_PER_HUB = 8

export default function ProjectInfoHubEditor({
  projectInfoHubId,
  project,
  infoHubChanged,
  setShowAddNodeDialog,
}: ProjectInfoHubEditorProps) {
  const MISSING_IMAGE = '/projectfiles/image-missing.svg'

  const { forcedReadOnly: readOnly } = useMindmapContext()

  // State
  const [showLoading, setShowLoading] = useState(false)

  // Queries and Mutations
  const {
    data: projectInfoHub,
    isLoading,
    isSuccess,
    isError,
  } = useProjectInfoHub(project.id ?? '')
  const uploadFileMutation = useUploadFile()
  const updateImageMutation = useUpdateProjectInfoHubImage()
  const { me } = useAuth()
  const tenantId = me?.primaryTenantId
  // Derived state
  const imageSrc = projectInfoHub?.imageId
    ? `/api/file/${me?.primaryTenantId}/file/${projectInfoHub.imageId}`
    : MISSING_IMAGE

  const onDropAccepted = async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return

    setShowLoading(true)
    try {
      // Upload the file
      const response = await uploadFileMutation.mutateAsync({
        file: acceptedFiles[0],
        fileType: 'image',
      })

      if (response.length === 1) {
        const imageId = response[0].id
        const updatedInfoHub = await updateImageMutation.mutateAsync({
          infoHubId: projectInfoHubId,
          imageId,
        })
        infoHubChanged(updatedInfoHub)
      } else {
        alert('Error uploading file')
      }
    } catch (error) {
      alert('Error updating image: ' + error)
    } finally {
      setShowLoading(false)
    }
  }

  const handleInfoNodeChanged = (infoNode: any) => {
    // Handle info node changes if needed
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error...</div>
  }

  if (!isSuccess) {
    return <div>Not found...</div>
  }

  const currentCount = project.projectInfoHub?.infoNodes?.length ?? 0

  return (
    <>
      <h4>{projectInfoHub.projectTitle}</h4>
      <Dropzone onDropAccepted={onDropAccepted} disabled={readOnly}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              style={{
                border: `1px ${readOnly ? 'solid' : 'dotted'} darkgray`,
                display: 'inline-flex',
                position: 'relative',
                borderRadius: '8px',
                padding: '4px',
              }}
            >
              <input {...getInputProps()} />
              <img
                src={imageSrc}
                className="rounded avatar_img"
                alt="Project"
              />
              {showLoading && <div className="uploading-text">Uploading..</div>}
            </div>
          </section>
        )}
      </Dropzone>

      {/*  {projectInfoHub.infoNodes?.map((infoNode) => (
        <InfoNodeEditor
          className="mt-2"
          infoNodeId={infoNode.id!}
          key={infoNode.id}
          project={project}
          infoNodeChanged={handleInfoNodeChanged}
        />
      ))} */}
      {!readOnly && (
        <Button
          disabled={currentCount >= MAX_NODES_PER_HUB}
          onClick={
            currentCount >= MAX_NODES_PER_HUB
              ? () => {}
              : () => setShowAddNodeDialog(true)
          }
          className="mt-2"
        >
          Lägg till..
        </Button>
      )}
    </>
  )
}
