import { useQueryClient } from '@tanstack/react-query'
import {
  GridOptions,
  ICellRendererParams,
  IRowNode,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-balham.css' // Optional Theme applied to the grid
import 'ag-grid-community/styles/ag-theme-material.css' // Optional Theme applied to the grid
import 'ag-grid-community/styles/ag-theme-quartz.css' // Optional Theme applied to the grid
import { AgGridReact } from 'ag-grid-react' // AG Grid Component
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Col,
  Collapse,
  Container,
  Form,
  InputGroup,
  Modal,
  Navbar,
  Row,
  Spinner,
} from 'react-bootstrap'
import 'react-bootstrap-tagsinput/dist/index.css'
import CandidateEditor from '../components/candidate/CandidateEditor'
import { FilesUpload } from '../components/FilesUpload'
import { AvatarRenderer } from '../components/table/AvatarRenderer'
import { DateAgeRenderer } from '../components/table/DateAgeRenderer'
import { EmailRenderer } from '../components/table/EmailRenderer'
import { LinkedInRenderer } from '../components/table/LinkedInRenderer'
import { PhoneRenderer } from '../components/table/PhoneRenderer'
import { TagsRenderer } from '../components/table/TagsRenderer'
import {
  candidateKeys,
  useCandidates,
  useDeleteCandidate,
} from '../hooks/queries/use-candidates'
import { Candidate } from '../types/candidate'

import './Candidates.css'

export const CandidatesPage = () => {
  const { data: candidates = [], isLoading } = useCandidates()

  if (isLoading) {
    return <div>Loading...</div>
  }

  return <Candidates candidates={candidates} />
}

export const Candidates = ({ candidates }: { candidates: Candidate[] }) => {
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate | null>(
    null
  )
  const showCandidate = useMemo(
    () => selectedCandidate !== null,
    [selectedCandidate]
  )
  const [showNewCandidate, setShowNewCandidate] = useState(false)
  const [showCvUpload, setShowCvUpload] = useState(false)
  const [showBatchPanel, setShowBatchPanel] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const deleteCandidateMutation = useDeleteCandidate()
  const gridApiRef = useRef<AgGridReact<Candidate>>(null)
  const [isPolling, setIsPolling] = useState(false)
  const [uploadedCount, setUploadedCount] = useState(0)
  const initialCandidateCount = useRef(candidates.length)
  const queryClient = useQueryClient()

  const handleUploadsComplete = (fileCount: number) => {
    setUploadedCount(fileCount)
    initialCandidateCount.current = candidates.length
    setIsPolling(true)
  }

  useEffect(() => {
    let pollInterval: NodeJS.Timeout

    if (isPolling) {
      pollInterval = setInterval(async () => {
        await queryClient.refetchQueries({ queryKey: candidateKeys.lists() })

        // Check if we have new candidates
        const newCandidateCount =
          candidates.length - initialCandidateCount.current

        // Stop polling if we've found all expected candidates or hit timeout
        if (newCandidateCount >= uploadedCount) {
          setIsPolling(false)
          setUploadedCount(0)
        }
      }, 2000) // Poll every 2 seconds

      // Stop polling after 30 seconds as a safety measure
      setTimeout(() => {
        setIsPolling(false)
        setUploadedCount(0)
      }, 30000)
    }

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval)
      }
    }
  }, [isPolling, candidates.length, uploadedCount, queryClient])

  const handleDelete = async (candidate: Candidate) => {
    try {
      await deleteCandidateMutation.mutateAsync(candidate.id)
    } catch (error) {
      alert('Error deleting candidate: ' + error)
    }
  }

  const handleCloseModal = () => {
    setSelectedCandidate(null)
    setShowNewCandidate(false)
  }

  const gridOptions: GridOptions<Candidate> = {
    alwaysShowHorizontalScroll: false,
    getRowId: (params) => params.data.id,
    pagination: false,
    rowSelection: 'multiple',
    autoSizeStrategy: {
      type: 'fitGridWidth',
      defaultMinWidth: 100,
      columnLimits: [{ colId: 'summary', maxWidth: 200 }],
    },
    columnDefs: [
      {
        field: 'avatarId',
        cellRenderer: AvatarRenderer,
        headerName: '',
        maxWidth: 65,
        sortable: false,
      },
      { field: 'name', headerName: 'Namn', maxWidth: 200 },
      {
        field: 'referenceProcessStatus',
        headerName: 'Referens',
        maxWidth: 170,
      },
      {
        field: 'email',
        headerName: 'Epost',
        cellRenderer: EmailRenderer,
        maxWidth: 110,
      },
      {
        field: 'phoneNumber',
        headerName: 'Telefon',
        cellRenderer: PhoneRenderer,
        maxWidth: 110,
      },
      {
        field: 'linkedInUrl',
        headerName: 'LinkedIn',
        cellRenderer: LinkedInRenderer,
        maxWidth: 110,
      },
      {
        field: 'tags',
        headerName: 'Taggar',
        cellRenderer: TagsRenderer,
        flex: 1,
      },
      {
        field: 'created',
        headerName: 'Skapad',
        cellRenderer: DateAgeRenderer,
        maxWidth: 150,
      },
      {
        field: 'updated',
        headerName: 'Uppdaterad',
        cellRenderer: DateAgeRenderer,
        maxWidth: 150,
        sort: 'desc',
      },
      {
        maxWidth: 100,
        cellRenderer: (params: ICellRendererParams<Candidate>) => (
          <div>
            <Button
              size="sm"
              title="Delete"
              variant="link"
              onClick={() => handleDelete(params.data!)}
            >
              <i className="bi bi-trash"></i>
            </Button>
            <Button
              size="sm"
              variant="link"
              title="Edit"
              onClick={() => {
                setSelectedCandidate(params.data ?? null)
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </Button>
          </div>
        ),
      },
    ],
  }

  const isExternalFilterPresent = useCallback(() => true, [])

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<Candidate>): boolean => {
      if (!searchFilter || !node.data) return true
      const regexp = new RegExp(searchFilter, 'i')
      return !!(
        (node.data.name && regexp.test(node.data.name)) ||
        (node.data.email && regexp.test(node.data.email)) ||
        (node.data.tags && regexp.test(node.data.tags.join()))
      )
    },
    [searchFilter]
  )

  const onSelectionChanged = (event: SelectionChangedEvent<Candidate>) => {
    setShowBatchPanel(event.api.getSelectedRows().length > 1)
  }

  const onRowDoubleClicked = (event: RowDoubleClickedEvent<Candidate>) => {
    setSelectedCandidate(event.data ?? null)
  }

  return (
    <>
      <Container className="pt-3">
        <Form className="form-tight">
          <Row className="mt-2">
            <Col>
              <Form.Control
                placeholder="Sök"
                value={searchFilter}
                onChange={(e) => {
                  setSearchFilter(e.target.value)
                  gridApiRef.current?.api.onFilterChanged()
                }}
              />
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  onClick={() => setShowNewCandidate(true)}
                  title="Lägg till kandidat"
                >
                  <i className="bi-plus-lg"></i>
                </Button>
                <Button
                  onClick={() => setShowCvUpload(!showCvUpload)}
                  title="Ladda upp CVn"
                >
                  <i className="bi-file-earmark-arrow-up"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Form>

        <Collapse in={showCvUpload}>
          <div>
            <FilesUpload onUploadsComplete={handleUploadsComplete} />
            {isPolling && (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  variant="primary"
                  size="sm"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <span className="spin"> Skapar kandidat...</span>
              </>
            )}
          </div>
        </Collapse>

        <div className="ag-theme-quartz mt-2" style={{ height: 500 }}>
          <AgGridReact<Candidate>
            gridOptions={gridOptions}
            ref={gridApiRef}
            rowData={candidates}
            onSelectionChanged={onSelectionChanged}
            onRowDoubleClicked={onRowDoubleClicked}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
          />
        </div>

        <Modal show={showNewCandidate} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Ny Kandidat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CandidateEditor id="" onClose={handleCloseModal} />
          </Modal.Body>
        </Modal>

        <Modal show={showCandidate} onHide={handleCloseModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>{selectedCandidate?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CandidateEditor
              id={selectedCandidate?.id!}
              onClose={handleCloseModal}
            />
          </Modal.Body>
        </Modal>
      </Container>

      {showBatchPanel && (
        <Container fluid>
          <Navbar
            fixed="bottom"
            className="bg-body-tertiary d-flex justify-content-center"
          >
            <Form className="d-flex align-items-center">
              <InputGroup className="me-2">
                <Form.Control placeholder="Taggar" />
                <Button>Applicera</Button>
              </InputGroup>
              <InputGroup className="me-2">
                <Form.Select aria-label="Betyg">
                  <option value="one">★</option>
                  <option value="two">★★</option>
                  <option value="three">★★★</option>
                  <option value="four">★★★★</option>
                  <option value="five">★★★★★</option>
                </Form.Select>
                <Button>Applicera</Button>
              </InputGroup>
              <InputGroup>
                <Button>
                  <i className="bi bi-envelope"></i>&nbsp;Skicka&nbsp;meddelande
                </Button>
              </InputGroup>
            </Form>
          </Navbar>
        </Container>
      )}
    </>
  )
}
