export enum WorkflowType {
  RecruitmentProcess = 'RecruitmentProcess',
  Reference = 'Reference',
  CandidateRecruitmentProcess = 'CandidateRecruitmentProcess',
}

export enum TaskStatus {
  ToDo = 'ToDo',
  InProgress = 'InProgress',
  Done = 'Done',
  Skipped = 'Skipped',
}

export interface Task {
  taskId: string
  dueDateTime?: string // LocalDateTime maps to string in TypeScript
  doneDateTime?: string // LocalDateTime maps to string in TypeScript
  assigneeUserId?: string
  status: TaskStatus
  title: string
  description: string
}

export interface Workflow {
  id?: string
  tenantId: string
  workflowType: WorkflowType
  tasks: Task[]
}

export interface UpdateWorkflowTaskAction {
  workflowId: string
  taskId: string
  assigneeUserId?: string
  status?: TaskStatus
  dueDateTime?: string // LocalDateTime maps to string in TypeScript
  updatedDateTime?: string // LocalDateTime maps to string in TypeScript
}
