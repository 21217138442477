import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { CompanyContact } from './CompanyEditor'
import { useAuth } from '../../auth/auth-provider'
import { useUserExists } from '../../hooks/queries/use-users'

interface ContactModalProps {
  show: boolean
  onHide: () => void
  onSave: (contact: CompanyContact, isMainContact: boolean) => void
  isMainContact?: boolean
  isLoading?: boolean
  initialContact?: CompanyContact
}

const ContactModal = ({
  show,
  onHide,
  onSave,
  isMainContact = true,
  isLoading = false,
  initialContact,
}: ContactModalProps) => {
  const { me } = useAuth()
  const [contact, setContact] = React.useState<CompanyContact>(
    initialContact ?? {
      firstName: '',
      lastName: '',
      primaryTenantId: me?.primaryTenantId,
      email: '',
      phone: '',
      isNew: true,
    }
  )

  // Simple email format validation
  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const [emailError, setEmailError] = React.useState('')
  const userExistsMutation = useUserExists()
  /* React.useEffect(() => {
    if (emailExists) {
      setEmailError('E-postadressen används redan')
    }
  }, [emailExists]) */

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value
    setContact({ ...contact, email: newEmail })

    /* if (newEmail && !isValidEmail(newEmail)) {
      setEmailError('Ogiltig e-postadress')
    } else {
      setEmailError('')
    } */
  }

  const handleEmailValidation = (newEmail: string) => {
    if (newEmail && !isValidEmail(newEmail)) {
      setEmailError('Ogiltig e-postadress')
      return false
    }

    userExistsMutation.mutate(
      {
        tenantId: me?.primaryTenantId ?? '',
        email: newEmail,
      },
      {
        onSuccess: (data) => {
          if (data) {
            setEmailError('E-postadressen finns redan registrerad')
            return
          }
          setEmailError('')
        },
      }
    )

    setEmailError('')
    return true
  }

  const handleClose = () => {
    setContact({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      isNew: true,
    })
    setEmailError('')
    onHide()
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        className="contact-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Lägg till kontaktperson</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="g-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Förnamn *</Form.Label>
                  <Form.Control
                    required
                    placeholder="Förnamn"
                    value={contact.firstName}
                    onChange={(e) =>
                      setContact({
                        ...contact,
                        firstName: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Efternamn</Form.Label>
                  <Form.Control
                    placeholder="Efternamn"
                    value={contact.lastName}
                    onChange={(e) =>
                      setContact({ ...contact, lastName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email"
                    value={contact.email}
                    onChange={handleEmailChange}
                    onBlur={(e) =>
                      e.target.value && handleEmailValidation(e.target.value)
                    }
                    isInvalid={!!emailError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Telefon</Form.Label>
                  <Form.Control
                    placeholder="Telefon"
                    value={contact.phone}
                    onChange={(e) =>
                      setContact({ ...contact, phone: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Avbryt
          </Button>
          <Button
            variant="primary"
            onClick={() => onSave(contact, isMainContact)}
            disabled={
              isLoading || !contact.firstName || !contact.email || !!emailError
            }
          >
            {isLoading ? 'Sparar...' : 'Lägg till'}
          </Button>
        </Modal.Footer>
      </Modal>

      <style>
        {`
          .contact-modal {
            z-index: 1060 !important;
          }
          
          .contact-modal .modal-content {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            border: none;
          }
          
          .modal-backdrop.show:nth-child(2) {
            z-index: 1055 !important;
          }
        `}
      </style>
    </>
  )
}

export default ContactModal
