import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../auth/auth-provider";
import { Role } from "../types/auth";

interface RestrictedRouteProps {
  children: React.ReactNode;
  roles: Role[];
}

export const RestrictedRoute: React.FC<RestrictedRouteProps> = ({
  children,
  roles,
}) => {
  const { isAuthenticated, isLoading, user, me } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated || !user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const hasRequiredRoles = roles.some((role) => me?.roles.includes(role));

  if (!hasRequiredRoles) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};
