import { ApiClient } from "./api-client";
import { Candidate } from "../types/candidate";

export class CandidateService {
  private basePath: string;

  constructor(private apiClient: ApiClient) {
    this.basePath = apiClient.getBaseUrl("candidate");
  }

  async getCandidates(): Promise<Candidate[]> {
    return this.apiClient.get<Candidate[]>(`${this.basePath}/candidate`);
  }

  async getCandidate(id: string): Promise<Candidate> {
    return this.apiClient.get<Candidate>(`${this.basePath}/candidate/${id}`);
  }

  async createCandidate(candidate: Candidate): Promise<Candidate> {
    return this.apiClient.post<Candidate>(
      `${this.basePath}/candidate`,
      candidate
    );
  }

  async updateCandidate(
    original: Candidate,
    edited: Candidate
  ): Promise<Candidate> {
    const patch = this.createPatch(original, edited);
    return this.apiClient.patch<Candidate>(
      `${this.basePath}/candidate/${original.id}`,
      patch
    );
  }

  async deleteCandidate(id: string): Promise<void> {
    return this.apiClient.delete(`${this.basePath}/candidate/${id}`);
  }

  private createPatch(
    original: Candidate,
    edited: Candidate
  ): Partial<Candidate> {
    const patch: Partial<Candidate> = {};

    if (original.avatarId !== edited.avatarId) patch.avatarId = edited.avatarId;
    if (original.name !== edited.name) patch.name = edited.name;
    if (original.summary !== edited.summary) patch.summary = edited.summary;
    if (original.email !== edited.email) patch.email = edited.email;
    if (original.linkedInUrl !== edited.linkedInUrl)
      patch.linkedInUrl = edited.linkedInUrl;
    if (original.expectedSalary !== edited.expectedSalary)
      patch.expectedSalary = edited.expectedSalary;
    if (original.noticePeriod !== edited.noticePeriod)
      patch.noticePeriod = edited.noticePeriod;
    if (original.phoneNumber !== edited.phoneNumber)
      patch.phoneNumber = edited.phoneNumber;
    if (original.rating !== edited.rating) patch.rating = edited.rating;
    if (original.referenceProcessStatus !== edited.referenceProcessStatus) {
      patch.referenceProcessStatus = edited.referenceProcessStatus;
    }
    if (original.referencePersonEmail !== edited.referencePersonEmail) {
      patch.referencePersonEmail = edited.referencePersonEmail;
    }
    if (original.referencePersonName !== edited.referencePersonName) {
      patch.referencePersonName = edited.referencePersonName;
    }
    if (original.referencePersonPhone !== edited.referencePersonPhone) {
      patch.referencePersonPhone = edited.referencePersonPhone;
    }

    if (!this.arrayEquals(original.tags, edited.tags)) {
      patch.tags = edited.tags?.map((t) =>
        typeof t === "string" ? t : t.name
      );
    }

    if (!this.arrayEquals(original.skills, edited.skills)) {
      patch.skills = edited.skills?.map((t) =>
        typeof t === "string" ? t : t.name
      );
    }

    return patch;
  }

  private arrayEquals<T>(a: T[], b: T[]): boolean {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }
}
