import { CandidateStatus } from '../types/project'
import { Task, TaskStatus } from '../types/workflow'

export const candidateStatus = {
  [CandidateStatus.Applied]: 'Ansökt',
  [CandidateStatus.InterviewByRecruiter]: 'Intervju, rekryterare',
  [CandidateStatus.PresentToClient]: 'Presenterad',
  [CandidateStatus.InterviewByClient]: 'Intervju m kund',
  [CandidateStatus.Tests]: 'Tester',
  [CandidateStatus.FinalInterview]: 'Slutintervju',
  [CandidateStatus.Reference]: 'Referenstagning',
  [CandidateStatus.OfferPresented]: 'Erbjudande',
  [CandidateStatus.Rejected]: 'Avförd',
  [CandidateStatus.Declined]: 'Avböjt',
  [CandidateStatus.Hired]: 'Avslutad',
}

export const getStatusLabel = (status: CandidateStatus) => {
  return candidateStatus[status]
}

export const candidateStatusColors = {
  [TaskStatus.ToDo]: '#0d6efd',
  [TaskStatus.InProgress]: '#0dcaf0',
  [TaskStatus.Done]: '#198754',
  [TaskStatus.Skipped]: '#6c757d',
}

export const getStatusColor = (status: TaskStatus) => {
  return candidateStatusColors[status]
}

export function getCurrentTask(tasks: Task[]): Task | undefined {
  // First try to find the last "Done" task
  const doneTasks = tasks.filter((task) => task.status === 'Done')
  if (doneTasks.length > 0) {
    return doneTasks[doneTasks.length - 1]
  }

  // If no "Done" tasks, try to find the last "InProgress" task
  const inProgressTasks = tasks.filter((task) => task.status === 'InProgress')
  if (inProgressTasks.length > 0) {
    return inProgressTasks[inProgressTasks.length - 1]
  }

  // If no "Done" or "InProgress" tasks, return the first task
  return undefined
}
