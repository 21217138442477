import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { User } from '../../types/user'
import { useApiClient } from '../useApiClient'
import { Role } from '../../types/auth'

export const userKeys = {
  all: ['users'] as const,
  lists: () => [...userKeys.all, 'list'] as const,
  list: (filters: string) => [...userKeys.lists(), { filters }] as const,
  details: () => [...userKeys.all, 'detail'] as const,
  detail: (id: string) => [...userKeys.details(), id] as const,
}

export const useUsers = (tenantId: string) => {
  const { usersService } = useApiClient()

  return useQuery({
    queryKey: userKeys.lists(),
    queryFn: () => usersService.getUsers({ tenantId, query: '' }),
  })
}

export const useUserExists = () => {
  const { usersService } = useApiClient()
  return useMutation({
    mutationFn: async ({
      tenantId,
      email,
    }: {
      tenantId: string
      email: string
    }) => {
      try {
        const users = await usersService.getUsers({
          tenantId,
          email: email,
        })
        if (users.length > 0) {
          return true
        }

        return false
      } catch (error) {
        console.error('User exists')
        return true
      }
    },
  })
}

// export const useUser = (id: string) => {
//   const { usersService } = useApiClient()

//   return useQuery({
//     queryKey: userKeys.detail(id),
//     queryFn: () => usersService.getUser(id),
//     enabled: !!id,
//   })
// }

export const useCreateUser = () => {
  const queryClient = useQueryClient()
  const { usersService } = useApiClient()

  return useMutation({
    mutationFn: (input: { user: User; roles: Role[] }) =>
      usersService.createUser(input.user, input.roles),
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}

export const useCreateUsers = () => {
  const queryClient = useQueryClient()
  const { usersService } = useApiClient()

  return useMutation({
    mutationFn: (input: { users: User[]; roles: Role[] }) =>
      usersService.createUsers(input.users, input.roles),
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}

// export const useUpdateUser = () => {
//   const queryClient = useQueryClient()
//   const { usersService } = useApiClient()

//   return useMutation({
//     mutationFn: ({ original, edited }: { original: User; edited: User }) =>
//       usersService.updateUser(original, edited),
//     onSuccess: () => {
//       queryClient.invalidateQueries()
//     },
//   })
// }

// export const useDeleteUser = () => {
//   const queryClient = useQueryClient()
//   const { usersService } = useApiClient()

//   return useMutation({
//     mutationFn: (id: string) => usersService.deleteUser(id),
//     onSuccess: () => {
//       queryClient.invalidateQueries()
//     },
//   })
// }
