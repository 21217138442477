import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import type { EventInfo } from '@ckeditor/ckeditor5-utils'
import { useEffect, useState } from 'react'
import { Button, Figure, Form } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { Document, Page, pdfjs } from 'react-pdf'
import { useAuth } from '../../auth/auth-provider'
import { useUploadFile } from '../../hooks/queries/use-files'
import {
  useAddFileToInfoNode,
  useDeleteInfoNodeFile,
  useInfoNode,
  useUpdateInfoNode,
} from '../../hooks/queries/use-projects'
import { useApiClient } from '../../hooks/useApiClient'
import { useMindmapContext } from '../../providers/mindmap-provider'
import { FileInfo, LinkInfo } from '../../types/common'
import { FormField, InfoNode, Project } from '../../types/project'
import FormFieldEditor from './FormFieldEditor'

import './InfoNodeEditor.css'

export default function InfoNodeEditor({
  infoNodeId,
  project,
  className,
}: {
  infoNodeId: string
  project: Project
  className?: string
}) {
  const { me } = useAuth()
  const { forcedReadOnly: readOnly } = useMindmapContext()

  const MISSING_IMAGE = '/projectfiles/image-missing.svg'

  const { projectsService } = useApiClient()

  // Local state
  const [showUploading, setShowUploading] = useState(false)
  const [uploadImageSrc, setUploadImageSrc] = useState(MISSING_IMAGE)
  const [showDropZone, setShowDropZone] = useState(false)
  const [edited, setEdited] = useState<InfoNode>()

  // Queries and mutations
  const { data: original, isLoading } = useInfoNode(infoNodeId)
  const updateInfoNodeMutation = useUpdateInfoNode()
  const deleteFileMutation = useDeleteInfoNodeFile()
  const addFileToInfoNodeMutation = useAddFileToInfoNode()
  const uploadFileMutation = useUploadFile()

  useEffect(() => {
    if (original) {
      setEdited(original)
    }
  }, [original])

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  const onDropAccepted = async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return

    setUploadImageSrc(MISSING_IMAGE)
    setShowUploading(true)

    try {
      const response = await uploadFileMutation.mutateAsync({
        file: acceptedFiles[0],
        fileType: 'image',
      })

      if (response.length === 1) {
        const fileInfo = response[0]
        await addFileToInfoNodeMutation.mutateAsync({
          nodeId: infoNodeId,
          addFile: { addFile: fileInfo },
        })
        setShowDropZone(false)
      } else {
        alert('Error uploading file')
      }
    } catch (error) {
      alert('Error updating: ' + error)
    } finally {
      setShowUploading(false)
    }
  }

  const removeFile = async (fileId: string) => {
    try {
      await deleteFileMutation.mutateAsync({ nodeId: infoNodeId, fileId })
    } catch (error) {
      alert('Error deleting file: ' + error)
    }
  }

  const doMagic = async () => {
    if (!edited?.suggestAction) return

    try {
      // Show loading state
      const loadingNode = {
        ...edited,
        text: "<center><img src='/projectfiles/spinner.gif' width=200/></center>",
      }
      setEdited(loadingNode)

      // Get suggested content
      const suggestedText = await projectsService.suggestRichTextContent(
        edited.suggestAction,
        project
      )

      setEdited({ ...edited, text: suggestedText })
    } catch (error) {
      alert('Error getting suggested content: ' + error)
    }
  }

  const save = async () => {
    if (!edited || edited.text === original?.text) return

    try {
      await updateInfoNodeMutation.mutateAsync({
        id: infoNodeId,
        patch: { text: edited.text },
      })
    } catch (error) {
      alert('Error updating text: ' + error)
    }
  }

  if (isLoading || !original || !edited) {
    return <div>Loading...</div>
  }

  // The rest of your render logic remains the same...
  switch (edited.type) {
    case 'RichText':
      return (
        <>
          <Form.Group
            className={`small-editor-container ${className}`}
            controlId="req.required_formal_competences"
          >
            <Form.Label>
              {edited.title}{' '}
              {edited.suggestAction && !readOnly && (
                <Button variant="link" style={{ padding: 0 }}>
                  <i className="bi bi-magic" onClick={doMagic}></i>
                </Button>
              )}
            </Form.Label>
            <div
              style={{ color: '#333' }}
              className={readOnly ? 'readonly-editor' : ''}
            >
              <CKEditor
                key={readOnly ? 'readonly-toolbar' : 'toolbar'}
                disabled={readOnly}
                editor={ClassicEditor}
                config={readOnly ? { toolbar: [] } : {}}
                data={edited?.text ?? ''}
                onChange={(event: EventInfo, editor: ClassicEditor) => {
                  setEdited({ ...edited, text: editor.getData() })
                }}
              />
            </div>
          </Form.Group>
          {!readOnly && (
            <Button
              disabled={edited.text === original.text}
              onClick={save}
              className="mt-2"
            >
              Save
            </Button>
          )}
        </>
      )
    case 'Form':
      return (
        <div className={className}>
          <h4 className="text-primary">{edited.title}</h4>
          {edited.formFields?.map((formField: FormField, i) => {
            return <FormFieldEditor formField={formField} key={i} />
          })}
        </div>
      )
    case 'Media':
      return (
        <div className={className}>
          <div className="d-flex mb-2">
            <h4 className="text-primary flex-grow-1">
              <i className="bi bi-paperclip"></i> {edited.title}
            </h4>
            {!readOnly && (
              <Button
                variant="primary"
                onClick={() => setShowDropZone(!showDropZone)}
              >
                <i className="bi bi-plus"></i>
              </Button>
            )}
          </div>
          <div hidden={!showDropZone} className="mt-2 mb-2">
            <Dropzone onDropAccepted={onDropAccepted} disabled={readOnly}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps()}
                    style={{
                      border: `1px ${readOnly ? 'solid' : 'dotted'} darkgray`,
                      display: 'inline-flex',
                      position: 'relative',
                      borderRadius: '8px',
                      padding: '4px',
                    }}
                  >
                    <input {...getInputProps()} />
                    <img src={uploadImageSrc} className="rounded avatar_img" />
                    {showUploading && (
                      <div className="uploading-text">Uploading..</div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="files-container">
            {edited.files?.map((file: FileInfo, i) => {
              if (file.contentType.includes('image')) {
                return (
                  <Figure key={i}>
                    <Figure.Image
                      src={`/api/file/${me?.primaryTenantId}/file/` + file.id}
                      rounded
                      className="file-media-image me-4"
                    />
                    <Figure.Caption>
                      {file.fileName}&nbsp;
                      <a
                        href={
                          `/api/file/${me?.primaryTenantId}/file/` + file.id
                        }
                        download={file.fileName}
                      >
                        <i className="bi bi-download"></i>
                      </a>
                      {!readOnly && (
                        <Button
                          variant="link"
                          size="sm"
                          onClick={() => {
                            removeFile(file.id)
                          }}
                        >
                          <i className="bi bi-trash"></i>
                        </Button>
                      )}
                    </Figure.Caption>
                  </Figure>
                )
              }
              if (file.contentType.includes('application/pdf')) {
                return (
                  <div key={i}>
                    <Document
                      file={`/api/file/${me?.primaryTenantId}/file/` + file.id}
                      className="pdf_document"
                    >
                      <Page
                        pageNumber={1}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        width={550}
                      />
                    </Document>
                    <a
                      href={`/api/file/${me?.primaryTenantId}/file/` + file.id}
                      download={file.fileName}
                    >
                      <i className="bi bi-download"></i>
                    </a>
                    {!readOnly && (
                      <Button
                        variant="link"
                        size="sm"
                        onClick={() => {
                          removeFile(file.id)
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </Button>
                    )}
                  </div>
                )
              } else
                return <div key={i}>Unknown file type: {file.contentType}</div>
            })}
          </div>
          <br />
          {edited.links?.map((link: LinkInfo, i) => {
            return (
              <a href={link.url} target="_blank">
                {link.title} <i className="bi bi-box-arrow-up-right"></i>
              </a>
            )
          })}
        </div>
      )
    default:
      return (
        <div className={className}>Unknown InfoNode type: {edited.type}</div>
      )
  }
}
