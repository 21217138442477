import { Route, Routes } from 'react-router-dom'
import { Admin } from '../pages/Admin'
import { CandidatesPage } from '../pages/Candidates'
import { Companies } from '../pages/Companies'
import { Home } from '../pages/Home'
import { ProjectPageNew } from '../pages/ProjectPageNew'
import { Projects } from '../pages/Projects'
import { RestrictedRoute } from './RestrictedRoute'

export const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="/recruiter"
        element={
          <RestrictedRoute roles={['ROLE_RECRUITER']}>
            <div>Recruiter</div>
          </RestrictedRoute>
        }
      />
      <Route
        path="/recruiter-client"
        element={
          <RestrictedRoute roles={['ROLE_RECRUITER-CLIENT']}>
            <div>RecruiterClient</div>
          </RestrictedRoute>
        }
      />
      <Route path="/unauthorized" element={<div>UNATUTHORIZED</div>} />
      <Route path="/projects/:id?" element={<Projects />} />
      <Route path="/project/:id?" element={<ProjectPageNew />} />
      <Route
        path="/candidates"
        element={
          <RestrictedRoute roles={['ROLE_ADMIN', 'ROLE_RECRUITER']}>
            <CandidatesPage />
          </RestrictedRoute>
        }
      />

      <Route
        path="/companies/:id?"
        element={
          <RestrictedRoute roles={['ROLE_ADMIN', 'ROLE_RECRUITER']}>
            <Companies />
          </RestrictedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <RestrictedRoute roles={['ROLE_ADMIN']}>
            <Admin />
          </RestrictedRoute>
        }
      />
      <Route path="/" element={<Home />} />
    </Routes>
  )
}
