import { Handle, Position } from '@xyflow/react'
import { memo } from 'react'
import { Badge } from 'react-bootstrap'
import { useAuth } from '../../auth/auth-provider'

export default memo(({ id, data }: { id: string; data: any }) => {
  const { me } = useAuth()
  return (
    <>
      <Badge
        style={{
          position: 'absolute',
          maxWidth: '76px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          zIndex: 500,
          bottom: 2,
          fontSize: '0.5rem',
          fontFamily: 'var(--default-font)',
          backgroundColor: data.status.color,
        }}
        bg="none"
      >
        {data.status.label}
      </Badge>
      <div
        style={{
          backgroundImage: `url(/api/file/${me?.primaryTenantId}/file/${data.image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '100%',
          borderRadius: '50%', // This ensures the inner div is also circular
          filter: data.active ? 'none' : 'grayscale(1)',
        }}
      >
        <Handle
          type="target"
          position={Position.Top}
          style={{ visibility: 'hidden', width: 0, height: 0 }}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ visibility: 'hidden', width: 0, height: 0 }}
        />
      </div>
    </>
  )
})
