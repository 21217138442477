import { useEffect, useMemo, useState } from 'react'
import { Button, Form, FormGroup, Modal, Row } from 'react-bootstrap'
import {
  Menu,
  MenuItem,
  MenuProps,
  RenderMenuProps,
  Typeahead,
} from 'react-bootstrap-typeahead'
import Dropzone from 'react-dropzone'
import { useAuth } from '../../auth/auth-provider'
import { useCompany, useUpdateCompany } from '../../hooks/queries/use-companies'
import { useUploadFile } from '../../hooks/queries/use-files'
import { useProject, useUpdateProject } from '../../hooks/queries/use-projects'
import { useCreateUser, useUsers } from '../../hooks/queries/use-users'
import { Project } from '../../types/project'
import { User } from '../../types/user'
import ProcessSteps from '../ProcessSteps'
import { TaskStatus } from '../../types/workflow'
import CompanyContacts from '../company/ContactPersonManager'
import ContactModal from '../company/ContactModal'
import { CompanyContact } from '../company/CompanyEditor'
import { Option } from 'react-bootstrap-typeahead/types/types'
import {
  useUpdateWorkflowStatus,
  useWorkflow,
} from '../../hooks/queries/use-workflows'

const AVATAR_MISSING = '/projectfiles/image-missing.svg'

interface ProjectEditorDialogProps {
  show: boolean
  companyId?: string
  projectId?: string
  onCancel: () => void
}

export default function ProjectEditorDialog({
  show,
  companyId,
  projectId,
  onCancel,
}: ProjectEditorDialogProps) {
  const { me } = useAuth()

  const [showUploading, setShowUploading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isMainContact, setIsMainContact] = useState(true)

  const [edited, setEdited] = useState<Project>({
    tags: [],
    recruiterUserId: me?.id,
    coRecruiters: [],
    companyId: '',
    mainContactUserId: '',
    companyName: '',
    title: '',
    backgroundImageId: '',
    position: '',
    desiredStartDate: '',
  })

  const company = useCompany(companyId || '')
  const createUser = useCreateUser()
  const updateCompany = useUpdateCompany()
  const contactOptions = useMemo(() => {
    if (!company.data) return []
    return company.data.contacts
  }, [company.data])

  const project = useProject(projectId || '')
  const workflow = useWorkflow(project.data?.recruitmentProcessWorkflowId || '')
  const updateProject = useUpdateProject()
  const uploadFile = useUploadFile()
  const updateStatusMutation = useUpdateWorkflowStatus()

  const recruiters = useUsers(me?.primaryTenantId || '')
  const recruiterOptions = useMemo(() => {
    if (!recruiters.data) return []

    return recruiters.data.filter(
      (user) => user.roles && user.roles.includes('ROLE_RECRUITER')
    )
  }, [recruiters.data])

  useEffect(() => {
    if (!project.data) return
    setEdited(project.data)
  }, [project.data])

  const handleApply = async () => {
    if (!project.data) return

    await updateProject.mutateAsync(
      {
        original: project.data,
        edited,
      },
      {
        onError: (error) => {
          alert('Error updating project: ' + error)
        },
        onSuccess: () => {
          onCancel()
        },
      }
    )
  }

  const onDropAccepted = async (acceptedFiles: File[]) => {
    setShowUploading(true)

    await uploadFile.mutateAsync(
      { file: acceptedFiles[0], fileType: 'image' },
      {
        onSuccess: (data) => {
          const backgroundImageId = data[0].id
          setEdited((prev) => ({ ...prev!, backgroundImageId }))
          setShowUploading(false)
        },
        onError: () => {
          setShowUploading(false)
          alert('Error uploading file')
        },
      }
    )
  }

  const getRecruiterName = () => {
    if (project.data?.recruiter) {
      return `${project.data?.recruiter?.firstName} ${project.data?.recruiter?.lastName}`
    } else {
      return project.data?.recruiterUserId
    }
  }

  const renderMenu = (
    results: User[],
    menuProps: RenderMenuProps,
    useMainContact: boolean
  ) => {
    const { id, className, role } = menuProps
    return (
      <Menu id={id} className={className} role={role}>
        <MenuItem
          position={0}
          onClick={() => {
            setShowModal(true)
            setIsMainContact(useMainContact)
          }}
          className="bg-light text-primary"
          option={{ ignore: true } as Option}
        >
          + Skapa ny kontaktperson
        </MenuItem>
        {results.map((result, index) => (
          <MenuItem key={result.id} option={result} position={index + 1}>
            {`${result.firstName ?? ''} ${result.lastName ?? ''}`}
          </MenuItem>
        ))}
      </Menu>
    )
  }

  const handleStatusChanged = (taskId: string, status: TaskStatus) => {
    const dueDateTime = new Date().toISOString()
    updateStatusMutation.mutate({
      taskId,
      status,
      workflowId: workflow.data?.id ?? '',
      updatedDateTime: dueDateTime,
      dueDateTime,
    })
  }

  return (
    <>
      <Modal size="xl" show={show} onHide={onCancel} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>
            {edited.position}, {edited.companyName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form-tight">
            <Row className="g-2">
              <FormGroup className="col-md-4">
                <Form.Label>Projekt-namn</Form.Label>
                <Form.Control
                  type="text"
                  value={edited.title}
                  onChange={(e) =>
                    setEdited((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                />
              </FormGroup>
              <FormGroup className="col-md-4">
                <Form.Label>Roll</Form.Label>
                <Form.Control
                  type="text"
                  value={edited.position}
                  onChange={(e) =>
                    setEdited((prev) => ({
                      ...prev,
                      position: e.target.value,
                    }))
                  }
                />
              </FormGroup>
              <FormGroup className="col-md-4">
                <Form.Label>Företag</Form.Label>
                <Form.Control
                  type="text"
                  value={edited.companyName}
                  onChange={(e) =>
                    setEdited((prev) => ({
                      ...prev,
                      companyName: e.target.value,
                    }))
                  }
                />
              </FormGroup>
              <FormGroup className="col-4">
                <Form.Label>Rekryterare</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={getRecruiterName()}
                  disabled
                />
              </FormGroup>
              <FormGroup className="col-8">
                <Form.Label>Medrekryterare</Form.Label>
                <Typeahead
                  id="project-co-recruiter-typeahead"
                  labelKey={(option) => {
                    const user = option as User
                    return `${user.firstName} ${user.lastName}`
                  }}
                  multiple
                  options={recruiterOptions}
                  placeholder="Medrekryterare..."
                  onChange={(selected) => {
                    setEdited((prev) => ({
                      ...prev,
                      coRecruiters: selected as User[],
                    }))
                  }}
                  selected={edited.coRecruiters}
                />
              </FormGroup>
              <FormGroup className="col-4">
                <Form.Label>Huvudkontaktperson hos kund</Form.Label>
                <Typeahead
                  id="project-main-contact-typeahead"
                  labelKey={(option) => {
                    const user = option as User
                    if ((user as any).isAddNew) return ''
                    if (!user.firstName || !user.lastName) return ''
                    return `${user.firstName ?? ''} ${user.lastName ?? ''}`
                  }}
                  options={contactOptions.length > 0 ? contactOptions : [{}]}
                  placeholder="Kontaktpersoner..."
                  renderMenu={(results, options) =>
                    renderMenu(results as User[], options, true)
                  }
                  onChange={(selected) => {
                    return setEdited({
                      ...edited,
                      mainContact: selected[0] as User,
                    })
                  }}
                  selected={edited.mainContact ? [edited.mainContact] : []}
                />
              </FormGroup>
              <FormGroup className="col-8">
                <Form.Label>Kontaktpersoner hos kund</Form.Label>
                <Typeahead
                  id="project-co-contacts-typeahead"
                  labelKey={(option) => {
                    const user = option as User
                    if (!user.firstName || !user.lastName) return ''
                    return `${user.firstName} ${user.lastName}`
                  }}
                  multiple
                  options={contactOptions.length > 0 ? contactOptions : [{}]}
                  renderMenu={(results, options) =>
                    renderMenu(results as User[], options, false)
                  }
                  placeholder="Kontaktpersoner..."
                  onChange={(selected) => {
                    // Not the best way to do this, but it works
                    // we dont call  setEdited here when clicking on the "add new" option
                    // so we have the ignore option to ignore the "add new" option
                    const option = selected[0] as any
                    if (
                      !selected ||
                      (option && 'ignore' in option && option.ignore)
                    )
                      return
                    const selectedContacts = selected as User[]
                    return setEdited({
                      ...edited,
                      contacts: selectedContacts,
                    })
                  }}
                  selected={edited.contacts || []}
                />
              </FormGroup>
              <FormGroup className="col-3">
                <Form.Label>Önskad start</Form.Label>
                <Form.Control
                  type="text"
                  value={edited.desiredStartDate}
                  onChange={(e) => {
                    setEdited((prev) => ({
                      ...prev,
                      desiredStartDate: e.target.value,
                    }))
                  }}
                />
              </FormGroup>
              <FormGroup className="col-9">
                <Form.Label>Taggar</Form.Label>
                <Typeahead
                  id="basic-typeahead-multiple"
                  labelKey="name"
                  multiple
                  allowNew
                  options={[]}
                  newSelectionPrefix="Lägg till ny tagg: "
                  placeholder="Tag..."
                  onChange={(selected) => {
                    setEdited((prev) => ({
                      ...prev,
                      tags: selected as string[],
                    }))
                  }}
                  selected={edited.tags}
                />
              </FormGroup>
              <FormGroup className="col-12">
                <Form.Label>Rekryteringsstatus</Form.Label>
                <ProcessSteps
                  steps={workflow.data?.tasks ?? []}
                  onStatusChanged={handleStatusChanged}
                ></ProcessSteps>
              </FormGroup>
            </Row>
            <Row>
              <div>
                <Dropzone
                  onDropAccepted={onDropAccepted}
                  accept={{
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/png': ['.png', '.PNG'],
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()} className="dropzone-container">
                        <input {...getInputProps()} />
                        <img
                          src={
                            edited.backgroundImageId
                              ? `/api/file/${me?.primaryTenantId}/file/` +
                                edited.backgroundImageId
                              : AVATAR_MISSING
                          }
                          alt={
                            edited.backgroundImageId
                              ? 'Project background image'
                              : 'Default avatar'
                          }
                          className="rounded"
                        />
                        {showUploading && (
                          <div className="uploading-text">Uploading..</div>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div className="d-flex">
                  <small className="ms-2 text-secondary">Photo</small>
                  {edited.backgroundImageId && (
                    <button
                      className="ms-auto btn btn-link p-0 border-0"
                      onClick={() =>
                        setEdited((prev) => ({
                          ...prev,
                          backgroundImageId: '',
                        }))
                      }
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  )}
                </div>
              </div>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Avbryt
          </Button>
          <Button variant="primary" onClick={handleApply}>
            Spara
          </Button>
        </Modal.Footer>
      </Modal>
      <ContactModal
        show={showModal}
        isMainContact={isMainContact}
        onHide={() => setShowModal(false)}
        onSave={async (contact: CompanyContact, isMainContact: boolean) => {
          try {
            // Create the user
            const newUser = await createUser.mutateAsync({
              user: contact as User,
              roles: ['ROLE_RECRUITER-CLIENT'],
            })

            // Update company with new contact
            if (company.data) {
              await updateCompany.mutateAsync({
                original: company.data,
                edited: {
                  ...company.data,
                  contactUserIds: [...company.data.contactUserIds, newUser.id],
                },
              })
            }

            // Set as main contact in the project
            if (isMainContact) {
              setEdited((prev) => ({
                ...prev,
                mainContact: newUser,
              }))
            } else {
              setEdited((prev) => ({
                ...prev,
                contacts: [...(prev.contacts ?? []), newUser],
              }))
            }

            setShowModal(false)
          } catch (error) {
            alert('Error creating contact: ' + error)
          }
        }}
        isLoading={createUser.isPending || updateCompany.isPending}
      />
    </>
  )
}
