import { Role } from '../types/auth'
import { User } from '../types/user'
import { ApiClient } from './api-client'

export class UserService {
  private basePath: string

  constructor(private apiClient: ApiClient) {
    this.basePath = apiClient.getBaseUrl('user', { useTenantId: false })
  }

  async getUsers(params: {
    tenantId: string
    query?: string
    email?: string
    role?: string
  }): Promise<User[]> {
    return this.apiClient.get<User[]>(`${this.basePath}/user`, {
      params,
    })
  }

  // async getUser(id: string): Promise<User> {
  //   return this.apiClient.get<User>(`${this.basePath}/user/${id}`);
  // }

  async createUser(user: User, roles: Role[]): Promise<User> {
    return this.apiClient.post<User>(`${this.basePath}/user`, {
      ...user,
      roles,
    })
  }

  async createUsers(users: User[], roles: Role[]): Promise<User[]> {
    return Promise.all(users.map((user) => this.createUser(user, roles)))
  }

  // async updateUser(original: User, edited: User): Promise<User> {
  //   const patch = this.createPatch(original, edited);
  //   return this.apiClient.patch<User>(
  //     `${this.basePath}/user/${original.id}`,
  //     patch
  //   );
  // }

  // async deleteUser(id: string): Promise<void> {
  //   return this.apiClient.delete(`${this.basePath}/user/${id}`);
  // }

  // private createPatch(original: User, edited: User): Partial<User> {
  //   const patch: Partial<User> = {};

  //   if (original.x !== edited.x) patch.x = edited.x;

  //   return patch;
  // }
}
