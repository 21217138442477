import { Dropdown } from 'react-bootstrap'
import { Task, TaskStatus } from '../types/workflow'

import './ProcessSteps.css'

interface ProcessStepsProps {
  steps: Task[]
  onStatusChanged?: (taskId: string, status: TaskStatus) => void
  disabled?: boolean
}

export default function ProcessSteps({
  steps,
  onStatusChanged,
  disabled,
}: ProcessStepsProps) {
  const getStylesForStatus = (status: TaskStatus) => {
    switch (status) {
      case TaskStatus.ToDo:
        return { backgroundColor: '#0d6efd' }
      case TaskStatus.InProgress:
        return { backgroundColor: '#0dcaf0' }
      case TaskStatus.Done:
        return { backgroundColor: '#198754' }
      case TaskStatus.Skipped:
        return { backgroundColor: '#6c757d' }
    }
  }

  const getIconForStatus = (status: TaskStatus) => {
    switch (status) {
      case TaskStatus.ToDo:
        return <i className="bi bi-dash "></i>
      case TaskStatus.InProgress:
        // white color
        return <i className="bi bi-clock text-black"></i>
      case TaskStatus.Done:
        return <i className="bi bi-check "></i>
      case TaskStatus.Skipped:
        return <i className="bi bi-exclamation "></i>
    }
  }

  const statusArray = Object.values(TaskStatus)
  return (
    <div className="stepwizard">
      <div className="stepwizard-row">
        {steps.map((step, index) => (
          <div className="stepwizard-step" key={step.taskId}>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="btn-circle"
                style={{
                  ...getStylesForStatus(step.status),
                  color: '#fff', // White text
                  opacity: disabled ? 0.65 : 1,
                }}
                aria-label={`Step ${index + 1}: ${step.title}`}
                disabled={disabled}
              >
                {getIconForStatus(step.status)}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ zIndex: 1060 }}>
                {statusArray.map((status) => (
                  <Dropdown.Item
                    disabled={status === step.status}
                    key={status}
                    onClick={() => onStatusChanged?.(step.taskId, status)}
                  >
                    {status}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <div>{step.title}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
