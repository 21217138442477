import { ApiClient } from "./api-client";

export class FileUploadService {
  private basePath: string;

  constructor(private apiClient: ApiClient) {
    this.basePath = apiClient.getBaseUrl("file");
  }

  async upload(
    file: File,
    fileType: string,
    onUploadProgress?: (progressEvent: any) => void
  ): Promise<any> {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("fileType", fileType);

    return this.apiClient.post(`${this.basePath}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  async getFiles(): Promise<any[]> {
    return this.apiClient.get<any[]>(`${this.basePath}/files`);
  }
}
