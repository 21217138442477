import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { useAuth } from '../auth/auth-provider'

export interface ApiConfig {
  baseURL: string
}

export class ApiClient {
  private axios: AxiosInstance
  private auth = useAuth()

  constructor(config: ApiConfig) {
    this.axios = axios.create({
      baseURL: config.baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.setupInterceptors()
  }

  private setupInterceptors() {
    this.axios.interceptors.request.use(
      (config) => {
        const token = this.auth.user?.access_token
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    this.axios.interceptors.response.use(
      (response) => response.data,
      async (error) => {
        if (error.response?.status === 401) {
          await this.auth.logout()
        }
        return Promise.reject(error)
      }
    )
  }

  getBaseUrl(
    service: string,
    config: { useTenantId: boolean } = { useTenantId: true }
  ): string {
    if (!config.useTenantId) {
      return `/api/${service}`
    } else {
      const tenantId = this.auth.me?.primaryTenantId
      return `/api/${service}/${tenantId}`
    }
  }

  get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.get(url, config)
  }

  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.post(url, data, config)
  }

  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.patch(url, data, config)
  }

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axios.delete(url, config)
  }
}
