import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Panel } from '@xyflow/react'
import { useMindmapContext } from '../../providers/mindmap-provider'
import NotificationModal from './NotificationModal'
import { useSendNotifications } from '../../hooks/queries/use-projects'

interface ControlPanelProps {
  projectId?: string
}

const ControlPanel = ({ projectId }: ControlPanelProps) => {
  const { forcedReadOnly, setForcedReadOnly } = useMindmapContext()
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const sendNotificationsMutation = useSendNotifications()
  return (
    <>
      <Panel position="top-right">
        <div className="bg-dark bg-opacity-75 rounded-3 shadow p-2 px-3 d-flex align-items-center text-white">
          <Form.Check
            type="switch"
            id="readOnly-switch"
            checked={forcedReadOnly}
            onChange={(e) => setForcedReadOnly(e.target.checked)}
            className="me-2"
          />
          <span className="me-3">Läsläge</span>
          <div className="border-start border-secondary ps-3">
            <Button
              variant="outline-light"
              size="sm"
              onClick={() => setShowNotificationModal(true)}
              className="d-flex align-items-center"
            >
              <i className="bi bi-bell-fill me-1"></i>
              Notiser
            </Button>
          </div>
        </div>
      </Panel>
      <NotificationModal
        show={showNotificationModal}
        onHide={() => setShowNotificationModal(false)}
        projectId={projectId ?? ''}
        onSubmit={sendNotificationsMutation.mutateAsync}
      />
    </>
  )
}

export default ControlPanel
