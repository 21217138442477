import { Company } from '../types/company'
import { ApiClient } from './api-client'

export class CompanyService {
  private basePath: string

  constructor(private apiClient: ApiClient) {
    this.basePath = apiClient.getBaseUrl('company')
  }

  async getCompanies(): Promise<Company[]> {
    return this.apiClient.get<Company[]>(`${this.basePath}/company`)
  }

  async getCompany(id: string): Promise<Company> {
    return this.apiClient.get<Company>(`${this.basePath}/company/${id}`)
  }

  async createCompany(company: Company): Promise<Company> {
    return this.apiClient.post<Company>(`${this.basePath}/company`, company)
  }

  async updateCompany(original: Company, edited: Company): Promise<Company> {
    const patch = this.createPatch(original, edited)
    return this.apiClient.patch<Company>(
      `${this.basePath}/company/${original.id}`,
      patch
    )
  }

  async deleteCompany(id: string): Promise<void> {
    return this.apiClient.delete(`${this.basePath}/company/${id}`)
  }

  private createPatch(original: Company, edited: Company): Partial<Company> {
    const patch: Partial<Company> = {}

    if (original.avatarId !== edited.avatarId) patch.avatarId = edited.avatarId
    if (original.name !== edited.name) patch.name = edited.name
    if (original.about !== edited.about) patch.about = edited.about

    if (!this.arrayEquals(original.tags, edited.tags)) {
      patch.tags = edited.tags?.map((t) => (typeof t === 'string' ? t : t.name))
    }

    if (!this.arrayEquals(original.contactUserIds, edited.contactUserIds)) {
      patch['contactUserIds'] = edited.contactUserIds.map((id) => {
        return id
      })
    }

    return patch
  }

  private arrayEquals<T>(a: T[], b: T[]): boolean {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    )
  }
}
