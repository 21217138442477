import { ColDef, ICellRendererParams } from "ag-grid-community"
import { AgGridReact } from "ag-grid-react"
import { useRef, useState } from "react"
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap"
import CompanyEditor from "../components/company/CompanyEditor"
import { DateAgeRenderer } from "../components/table/DateAgeRenderer"
import { LogoRenderer } from "../components/table/LogoRenderer"
import { TagsRenderer } from "../components/table/TagsRenderer"
import { useCompanies, useDeleteCompany } from "../hooks/queries/use-companies"
import { Company } from "../types/company"

export const Companies = () => {
  const [showCompany, setShowCompany] = useState(false)
  const [showNewCompany, setShowNewCompany] = useState(false)

  const { data: companies = [] } = useCompanies()

  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(
    undefined
  )

  const deleteCompanyMutation = useDeleteCompany()
  function handleDeleteCompany(company: Company) {
    deleteCompanyMutation.mutate(company.id)
  }

  const [searchText, setSearchText] = useState("")
  const gridApi = useRef<AgGridReact<Company>>(null)
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchText(value)

    if (gridApi.current?.api) {
      gridApi.current.api.setGridOption("quickFilterText", value)
    }
  }

  const [columnDefs, setColumnDefs] = useState<ColDef<Company, any>[]>([
    {
      field: "avatarId",
      cellRenderer: LogoRenderer,
      headerName: "",
      maxWidth: 65,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Namn",
      sortable: true,
      filter: true,
      maxWidth: 200,
    },
    { field: "about", headerName: "Om", sortable: true, filter: true },
    {
      field: "tags",
      headerName: "Taggar",
      cellRenderer: TagsRenderer,
      maxWidth: 200,
    },
    {
      field: "created",
      headerName: "Skapat",
      cellRenderer: DateAgeRenderer,
      maxWidth: 150,
    },
    {
      field: "updated",
      headerName: "Uppdaterat",
      cellRenderer: DateAgeRenderer,
      sort: "desc",
      maxWidth: 150,
    },
    {
      maxWidth: 100,
      cellRenderer: (params: ICellRendererParams<Company>) => {
        return (
          <div>
            <Button
              size='sm'
              title='Delete'
              variant='link'
              onClick={() => handleDeleteCompany(params.data!)}
            >
              <i className='bi bi-trash'></i>
            </Button>
            <Button
              size='sm'
              variant='link'
              title='Quick edit'
              onClick={() => {
                setSelectedCompany(params.data)
                setShowCompany(true)
              }}
            >
              <i className='bi bi-pencil-square'></i>
            </Button>
          </div>
        )
      },
    },
  ])

  return (
    <>
      <Modal
        show={showNewCompany}
        onHide={() => setShowNewCompany(false)}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Ny Kund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompanyEditor id='' onClose={() => setShowNewCompany(false)} />
        </Modal.Body>
      </Modal>
      <Modal size='xl' show={showCompany} onHide={() => setShowCompany(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCompany?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompanyEditor
            id={selectedCompany?.id!}
            onClose={() => setShowCompany(false)}
          />
        </Modal.Body>
      </Modal>

      <Container className='pt-3'>
        <Form>
          <Row className='mt-2'>
            <Col>
              <Form.Control
                placeholder='Sök'
                value={searchText}
                onChange={onSearchChange}
              />
            </Col>
            <Col>
              <ButtonGroup>
                <Button
                  onClick={() => setShowNewCompany(true)}
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Ny kund'
                >
                  <i className='bi-plus-lg'></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col>
            <div
              className='ag-theme-quartz mt-2' // applying the grid theme
              style={{ height: 500 }} // the grid will fill the size of the parent container
            >
              <AgGridReact<Company>
                getRowId={(data) => data.data.id}
                rowSelection='multiple'
                autoSizeStrategy={{
                  type: "fitGridWidth",
                  defaultMinWidth: 100,
                }}
                ref={gridApi}
                rowData={companies}
                columnDefs={columnDefs}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
