// src/index.tsx
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { WebStorageStateStore } from "oidc-client-ts"
import React from "react"
import "react-bootstrap-typeahead/css/Typeahead.css"
import ReactDOM from "react-dom/client"
import { AuthProvider as OidcProvider } from "react-oidc-context"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { AuthProvider } from "./auth/auth-provider"
import reportWebVitals from "./reportWebVitals"

import "./custom.scss"
import "./index.css"

const deepLinkPath = localStorage.getItem("deepLinkPath")
const redirect_uri = deepLinkPath?.includes("/project/")
  ? `${window.location.origin}${deepLinkPath}`
  : window.location.origin

const oidcConfig = {
  authority: (window as any).env.auth_authority,
  realm: (window as any).env.auth_realm,
  client_id: (window as any).env.auth_client_id,
  redirect_uri,
  scope: "openid profile email offline_access",
  response_type: "code",
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: () => {
    window.location.href = redirect_uri
  },
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 3,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <OidcProvider {...oidcConfig}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </OidcProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals()
