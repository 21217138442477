import { ICellRendererParams } from "ag-grid-community";
import { useAuth } from "../../auth/auth-provider";

export const AvatarRenderer = (params: ICellRendererParams) => {
  const { me } = useAuth();
  const imageUrl = params.value
    ? `/api/file/${me?.primaryTenantId}/file/${params.value}`
    : "/projectfiles/avatar_anonymous.png";

  return (
    <img
      src={imageUrl}
      className="rounded-circle"
      style={{
        height: params.value ? "32px" : "40px",
        width: params.value ? "32px" : "30px",
        objectFit: params.value ? "cover" : undefined,
        transform: params.value ? "none" : undefined,
      }}
      alt="avatar"
    />
  );
};
