import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

interface NotificationModalProps {
  show: boolean
  onHide: () => void
  projectId: string
  onSubmit: (data: {
    projectId: string
    sendToContacts: boolean
    sendToCoRecruiters: boolean
    sendToMainContact: boolean
  }) => Promise<void>
}

const NotificationModal = ({
  show,
  onHide,
  projectId,
  onSubmit,
}: NotificationModalProps) => {
  const [formState, setFormState] = useState({
    message: '',
    sendToContacts: true,
    sendToCoRecruiters: true,
    sendToMainContact: true,
  })

  const handleSubmit = async () => {
    try {
      await onSubmit({
        projectId,
        ...formState,
      })
      onHide()
    } catch (error) {
      console.error('Error sending notifications:', error)
    }
  }

  const handleCheckboxChange = (field: keyof typeof formState) => {
    setFormState((prev) => ({
      ...prev,
      [field]: !prev[field],
    }))
  }

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormState((prev) => ({
      ...prev,
      message: event.target.value,
    }))
  }

  return (
    <Modal show={show} centered backdrop="static" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Skicka notiser</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Skicka notiser om ändringar i projektet.</p>
        <Form>
          <Form.Check
            type="checkbox"
            id="sendToContacts"
            label="Skicka till kontakter"
            checked={formState.sendToContacts}
            onChange={() => handleCheckboxChange('sendToContacts')}
            className="mb-2"
          />
          <Form.Check
            type="checkbox"
            id="sendToCoRecruiters"
            label="Skicka till medrekryterare"
            checked={formState.sendToCoRecruiters}
            onChange={() => handleCheckboxChange('sendToCoRecruiters')}
            className="mb-2"
          />
          <Form.Check
            type="checkbox"
            id="sendToMainContact"
            label="Skicka till huvudkontakt"
            checked={formState.sendToMainContact}
            onChange={() => handleCheckboxChange('sendToMainContact')}
          />
          <Form.Group className="mt-3">
            <Form.Label>Meddelande</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formState.message}
              onChange={handleMessageChange}
              placeholder="Skriv ditt meddelande här..."
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!formState.message.trim()}
        >
          Skicka notiser
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NotificationModal
