import { Form, FormGroup } from 'react-bootstrap'
import { useMindmapContext } from '../../providers/mindmap-provider'
import { FormField } from '../../types/project'

export default function FormFieldEditor({
  formField,
}: {
  formField: FormField
}) {
  const { forcedReadOnly: readOnly } = useMindmapContext()

  switch (formField.type) {
    case 'Radio':
      return (
        <>
          <div className="mt-2">{formField.question}</div>

          {formField.options.map((option, index) => {
            return (
              <div key={index}>
                <Form.Check
                  inline
                  disabled={readOnly}
                  type="radio"
                  label={option}
                  name={option}
                  onChange={(e) => {
                    formField.values = [e.target.value]
                  }}
                  checked={formField.values?.includes(option)}
                  id={'option_' + index}
                />
              </div>
            )
          })}
        </>
      )
    case 'Checkbox':
      return (
        <>
          <div className="mt-2">{formField.question}</div>

          {formField.options.map((option, index) => {
            return (
              <div key={index}>
                <Form.Check
                  inline
                  disabled={readOnly}
                  type="checkbox"
                  label={option}
                  name={option}
                  onChange={(e) => {
                    formField.values = [e.target.value]
                  }}
                  checked={formField.values?.includes(option)}
                  id={'option_' + index}
                ></Form.Check>
              </div>
            )
          })}
        </>
      )
    case 'Select':
      return (
        <>
          <div className="mt-2">{formField.question}</div>

          {formField.options.map((option, index) => {
            return (
              <div key={index}>
                <Form.Select
                  disabled={readOnly}
                  onChange={(e) => {
                    formField.values = [e.target.value]
                  }}
                  value={formField.values?.[0]}
                  id={'option_' + index}
                />
              </div>
            )
          })}
        </>
      )
    case 'Text':
      return (
        <FormGroup className="mt-2">
          <Form.Label>{formField.question}</Form.Label>
          <Form.Control
            type="text"
            disabled={readOnly}
            value={formField.values?.[0]}
            onChange={(e) => {
              formField.values = [e.target.value]
            }}
          />
        </FormGroup>
      )
    default:
      return <div>Unknown FormField type: {formField.type}</div>
  }
}
