import React, { memo } from "react";
import { Handle, Position } from "@xyflow/react";

export default memo(({ id }: { id: string }) => {
  return (
    <div>
      <div></div>
    </div>
  );
});
