import { Edge, Node, XYPosition } from '@xyflow/react'
import { CandidateStatus, InfoNode } from '../types/project'
import {
  candidateStatusColors,
  getCurrentTask,
  getStatusColor,
  getStatusLabel,
} from './candidate-status'
import { ProjectWithWorkflows } from '../hooks/queries/use-projects'
import { Task, TaskStatus, Workflow } from '../types/workflow'

const PROJECT_HUB_RADIUS = 60
const HUB_RADIUS = 50
const HUB_DISTANCE = 300
const INFO_RADIUS = 15
const INFO_DISTANCE = 5
const INFO_SPREAD_ANGLE = Math.PI / 5
const ANNOTATION_DISTANCE = 10 // Distance from info node for annotation
const ANNOTATION_HEIGHT = 0
const TITLE_OFFSET = -35
const SUBTITLE_OFFSET = -20

export const createNodesForProject = (
  project: ProjectWithWorkflows,
  projectWorkflow: Workflow
) => {
  const projectId = project.projectInfoHub?.id ?? 'project'
  const projecHub = createProjectHub(project, projectId, projectWorkflow)
  const candidates = createCandidateHubs(project, projectId)

  return [...projecHub, ...candidates]
}

export const createEdgesForProject = (
  project: ProjectWithWorkflows,
  parentNodeId: string
): Edge[] => {
  const edges: Edge[] = []
  const candidates = project.candidateInfoHubs
  if (candidates) {
    candidates.forEach((candidate, index) => {
      const candidateId = candidate.id ?? `project-candidate-hub-${index}`
      const edge: Edge = {
        id: `edge-${index}`,
        source: parentNodeId,
        target: candidateId,
        type: 'straight',
        selectable: false,
      }
      edges.push(edge)
    })
  }

  return edges
}

const createProjectHub = (
  project: ProjectWithWorkflows,
  projectId: string,
  projectWorkflow: Workflow
) => {
  let infoNodes: InfoNode[] = []

  if (
    project.projectInfoHub !== undefined &&
    project.projectInfoHub.infoNodes !== undefined
  ) {
    infoNodes = project.projectInfoHub.infoNodes
  }
  const projectInfoNodes = createInfoNodes(infoNodes, projectId, true)

  let currentTask: Task | undefined
  if (projectWorkflow.tasks !== undefined && projectWorkflow.tasks.length > 0) {
    currentTask = getCurrentTask(projectWorkflow.tasks)
  }

  const projectHubNodes: Node[] = [
    {
      id: projectId,
      position: { x: 0, y: 0 },
      type: 'project',
      origin: [0.5, 0.5],
      data: {
        image: project.projectInfoHub?.imageId,
        status: {
          label: currentTask?.title ?? '',
          color: candidateStatusColors[currentTask?.status ?? TaskStatus.ToDo],
        },
      },
    },
    {
      id: projectId + '-title',
      selectable: false,
      position: { x: PROJECT_HUB_RADIUS, y: TITLE_OFFSET },
      type: 'title',
      data: { label: project.projectInfoHub?.projectTitle },
      parentId: projectId,
      origin: [0.5, 0.5],
      draggable: false,
    },
    {
      id: projectId + '-subtitle',
      selectable: false,
      position: { x: PROJECT_HUB_RADIUS, y: SUBTITLE_OFFSET },
      type: 'subtitle',
      data: { label: project.projectInfoHub?.companyName },
      parentId: projectId,
      origin: [0.5, 0.5],
      draggable: false,
    },
  ]

  if (projectInfoNodes !== null && projectInfoNodes !== undefined) {
    for (const infoNode of projectInfoNodes) {
      projectHubNodes.push(infoNode)
    }
  }

  return projectHubNodes
}

const createInfoNodes = (
  infoNodes: InfoNode[],
  parentNodeId: string,
  project = false
) => {
  let createdNodes: Node[] = []
  // project info node
  infoNodes.forEach((candidateNode, index) => {
    const infoNodeId = candidateNode.id ?? `${parentNodeId}-info-node-${index}`
    const pos = project
      ? calcInfoNodePositionProject(index)
      : calcInfoNodePosition(index)
    createdNodes.push({
      id: infoNodeId,
      data: {},
      position: pos,
      type: 'info',
      parentId: parentNodeId,
      draggable: false,
      origin: [0.5, 0.5],
    })

    // Create annotation that points outward from the circle
    createdNodes.push({
      id: `${infoNodeId}-annotation`,
      data: {
        label: candidateNode.title ?? '',
      },
      position: calcAnnotationPosition(index, candidateNode.title ?? ''),
      parentId: infoNodeId,
      type: 'annotation',
      selectable: false,
      draggable: false,
      origin: [0.5, 0.5],
    })
  })

  return createdNodes
}

const isCandidateInfoHubActive = (status: CandidateStatus): boolean => {
  return (
    status !== CandidateStatus.Rejected && status !== CandidateStatus.Declined
  )
}

const createCandidateHubs = (
  project: ProjectWithWorkflows,
  parentNodeId: string
) => {
  let candidateHubs: Node[] = []

  if (project.candidateInfoHubs) {
    project.candidateInfoHubs.forEach((candidateInfoHub, index) => {
      const candidateId =
        candidateInfoHub.id ?? `project-candidate-hub-${index}`
      const candidatePosition = calcCandidatePosition(
        project.candidateInfoHubs?.length ?? 0,
        index
      )

      let currentTask: Task | undefined
      if (candidateInfoHub.candidateRecruitmentWorkflow) {
        currentTask = getCurrentTask(
          candidateInfoHub.candidateRecruitmentWorkflow.tasks ?? []
        )
      }

      // Candidate hub
      candidateHubs.push({
        id: candidateId,
        position: candidatePosition,
        type: 'candidate',
        origin: [0.5, 0.5],
        data: {
          image: candidateInfoHub.imageId,
          active: isCandidateInfoHubActive(candidateInfoHub.status),
          status: {
            label: currentTask?.title ?? '',
            color:
              candidateStatusColors[currentTask?.status ?? TaskStatus.ToDo],
          },
        },
      })

      // Candidate hub title
      candidateHubs.push({
        id: `${candidateId}-title`,
        selectable: false,
        position: { x: HUB_RADIUS, y: TITLE_OFFSET },
        type: 'title',
        data: { label: candidateInfoHub.candidateName },
        parentId: candidateId,
        origin: [0.5, 0.5],
        draggable: false,
      })

      candidateHubs.push({
        id: `${candidateId}-subtitle`,
        selectable: false,
        position: { x: HUB_RADIUS, y: SUBTITLE_OFFSET },
        type: 'subtitle',
        data: { label: candidateInfoHub.candidatetagLine },
        parentId: candidateId,
        origin: [0.5, 0.5],
        draggable: false,
      })

      if (
        candidateInfoHub.infoNodes !== undefined &&
        candidateInfoHub.infoNodes.length > 0
      ) {
        const infoNodes = createInfoNodes(
          candidateInfoHub.infoNodes,
          candidateId
        )

        for (const infoNode of infoNodes) {
          candidateHubs.push(infoNode)
        }
      }
    })
  }

  return candidateHubs
}

const calcCandidatePosition = function (
  nCandidates: number,
  i: number
): XYPosition {
  let distance = HUB_DISTANCE
  const hubSpreadAngle = Math.PI / 4
  let startAngle = Math.PI / 2 + ((nCandidates - 1) * hubSpreadAngle) / 2
  if (i > 7) {
    distance = distance * 1.6
    startAngle += hubSpreadAngle / 2
  }

  const x = distance * Math.cos(startAngle - i * hubSpreadAngle)
  const y = distance * Math.sin(startAngle - i * hubSpreadAngle)

  return { x: x, y: y }
}

const calcAnnotationPosition = function (i: number, label: string): XYPosition {
  const labelWidth = label.length * 2.5
  const startAngle = -Math.PI / 5
  const angle = startAngle + INFO_SPREAD_ANGLE * i

  return {
    x:
      INFO_RADIUS +
      (INFO_RADIUS + labelWidth + ANNOTATION_DISTANCE) * Math.cos(angle),
    y:
      INFO_RADIUS +
      (INFO_RADIUS + ANNOTATION_HEIGHT + ANNOTATION_DISTANCE) * Math.sin(angle),
  }
}

const calcInfoNodePosition = function (i: number): XYPosition {
  const startAngle = -Math.PI / 5
  const angle = startAngle + INFO_SPREAD_ANGLE * i

  return {
    x:
      HUB_RADIUS + (HUB_RADIUS + INFO_RADIUS + INFO_DISTANCE) * Math.cos(angle),
    y:
      HUB_RADIUS + (HUB_RADIUS + INFO_RADIUS + INFO_DISTANCE) * Math.sin(angle),
  }
}

const calcInfoNodePositionProject = function (i: number): XYPosition {
  const startAngle = -Math.PI / 5
  const angle = startAngle + INFO_SPREAD_ANGLE * i

  return {
    x:
      PROJECT_HUB_RADIUS +
      (PROJECT_HUB_RADIUS + INFO_RADIUS + INFO_DISTANCE) * Math.cos(angle),
    y:
      PROJECT_HUB_RADIUS +
      (PROJECT_HUB_RADIUS + INFO_RADIUS + INFO_DISTANCE) * Math.sin(angle),
  }
}
