import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApiClient } from "../useApiClient";

export const fileKeys = {
  all: ["files"] as const,
  lists: () => [...fileKeys.all, "list"] as const,
};

export const useFiles = () => {
  const { filesService } = useApiClient();

  return useQuery({
    queryKey: fileKeys.lists(),
    queryFn: () => filesService.getFiles(),
  });
};

export const useUploadFile = () => {
  const queryClient = useQueryClient();
  const { filesService } = useApiClient();

  return useMutation({
    mutationFn: ({
      file,
      fileType,
      onUploadProgress,
    }: {
      file: File;
      fileType: string;
      onUploadProgress?: (progressEvent: any) => void;
    }) => filesService.upload(file, fileType, onUploadProgress),
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};
