import React from 'react'
import { Role } from '../types/auth'
import { useAuth } from '../auth/auth-provider'

interface RestrictedProps {
  children: React.ReactNode
  roles: Role[]
}

export const Restricted = ({ children, roles }: RestrictedProps) => {
  const { me } = useAuth()

  if (!me) return null
  const hasRequiredRoles = roles.some((role) => me.roles.includes(role))

  if (!hasRequiredRoles) {
    return null
  } else {
    return <>{children}</>
  }
}
