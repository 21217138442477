import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useApiClient } from '../useApiClient'
import { TaskStatus, UpdateWorkflowTaskAction } from '../../types/workflow'

export const projectKeys = {
  all: ['workflows'] as const,
  lists: () => [...projectKeys.all, 'list'] as const,
  list: (filters: string) => [...projectKeys.lists(), { filters }] as const,
  details: () => [...projectKeys.all, 'detail'] as const,
  detail: (id: string) => [...projectKeys.details(), id] as const,
}

export const useWorkflow = (id: string) => {
  const { workflowsService } = useApiClient()

  return useQuery({
    queryKey: projectKeys.detail(id),
    queryFn: () => workflowsService.getWorkflow(id),
    enabled: !!id,
  })
}

export const useUpdateWorkflowStatus = () => {
  const queryClient = useQueryClient()
  const { workflowsService } = useApiClient()

  return useMutation({
    mutationFn: (updateWorkflowStatus: UpdateWorkflowTaskAction) =>
      workflowsService.updateWorkflowStatus(updateWorkflowStatus),
    onSuccess: (_, { workflowId }) => {
      queryClient.invalidateQueries()
    },
  })
}
