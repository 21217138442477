import { Container, Image } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from './auth/auth-provider'
import { Restricted } from './components/Restricted'
import { AppRouter } from './routes'

import logo from './aday_logo.png'
import logo_white from './aday_logo_white.png'
import './App.css'
import './symbols.css'

function App() {
  const { user, isAuthenticated, isLoading, login, logout } = useAuth()
  const { pathname } = useLocation()

  const profilePicture =
    user?.profile?.picture ?? '/projectfiles/avatar_anonymous.png'

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isAuthenticated) {
    return (
      <div>
        <div className="header">
          <Navbar expand="md" className="navbar-dark">
            <Container fluid>
              <Navbar.Brand
                href="#home"
                as={Link}
                to="/"
                className="bg-transparent"
              >
                <img
                  src={logo_white}
                  height="30px"
                  className="d-inline-block align-top"
                  alt="Watego logo"
                  style={{ marginBottom: '6px' }}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse>
                <Nav className="me-auto">
                  <Nav.Link
                    href="#projects"
                    as={Link}
                    to="/projects"
                    eventKey="/projects"
                    active={pathname === '/projects'}
                  >
                    <i className="bi-list-check"> </i> Projekt
                  </Nav.Link>
                  <Restricted roles={['ROLE_ADMIN', 'ROLE_RECRUITER']}>
                    <Nav.Link
                      href="#candidates"
                      as={Link}
                      to="/candidates"
                      eventKey="/candidates"
                      active={pathname === '/candidates'}
                    >
                      <i className="bi-person-bounding-box"> </i>Kandidater
                    </Nav.Link>
                  </Restricted>
                  <Restricted roles={['ROLE_ADMIN', 'ROLE_RECRUITER']}>
                    <Nav.Link
                      href="#companies"
                      as={Link}
                      to="/companies"
                      eventKey="/companies"
                      active={pathname === '/companies'}
                    >
                      <i className="bi-buildings"> </i> Kunder
                    </Nav.Link>
                  </Restricted>
                  <Restricted roles={['ROLE_ADMIN']}>
                    <NavDropdown
                      title={
                        <span className="bi bi-person-fill-gear">
                          {' '}
                          Administration
                        </span>
                      }
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item
                        href="#tenants"
                        as={Link}
                        to="/admin"
                        eventKey="/admin"
                        active={pathname === '/admin'}
                      >
                        Rekryteringsföretag
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#users">
                        Användare
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="#action/3.4">
                        Rapporter
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Restricted>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse className="justify-content-end">
                <NavDropdown
                  title={
                    <div className="icon-badge-group">
                      <div className="icon-badge-container">
                        <i className="bi bi-bell-fill icon-badge-icon"></i>
                        {/* <div className="icon-badge">6</div> */}
                      </div>
                      &nbsp;&nbsp;&nbsp;
                    </div>
                  }
                  id="basic-nav-dropdown"
                  className="me-4"
                  align="end"
                >
                  <NavDropdown.Item href="#tenants" as={Link} to="/project/123">
                    {/*  <b>Bo Lundgren</b> har kommenterat{' '}
                    <Link to="/project/123">Målare</Link> till Hanssons Måleri
                    A" */}
                  </NavDropdown.Item>
                </NavDropdown>

                <Navbar.Text>
                  <Image roundedCircle src={profilePicture} height={32} />
                  &nbsp;
                </Navbar.Text>
                <NavDropdown
                  align="end"
                  title={
                    <span className="nav-tweak">{user?.profile.name}</span>
                  }
                >
                  <NavDropdown.Item onClick={() => logout()}>
                    Logga ut
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Inställningar
                  </NavDropdown.Item>
                </NavDropdown>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="content">
          <AppRouter />
          <div className="version-bottom">
            {(window as any).env.build_info}
            <br />
            {(window as any).env.build_nr}
            <br />
            {(window as any).env.build_id}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="image-container"></div>
      <div className="lower-container"></div>
      <div className="bottom-container"></div>
      <div className="login-container">
        <div className="text-center">
          <img src={logo} alt="Aday logo" className="logo" />
          <p className="mt-2 ms-5 me-5 text-primary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <p>
            <Button onClick={() => login()}>Log in</Button>
          </p>
        </div>
      </div>
      <div className="version-bottom">
        {(window as any).env.build_info}
        <br />
        {(window as any).env.build_nr}
        <br />
        {(window as any).env.build_id}
      </div>
    </div>
  )
}

export default App
