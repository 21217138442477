import { createContext, useContext, useState } from 'react'
import { useReadOnly } from '../hooks/read-only/use-read-only'

type MindmapContextType = {
  readOnly: boolean
  forcedReadOnly: boolean
  setForcedReadOnly: (forcedReadOnly: boolean) => void
}

const MindmapContext = createContext<MindmapContextType | null>(null)

export const MindmapProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const readOnly = useReadOnly()
  const [forcedReadOnly, setForcedReadOnly] = useState(false)

  return (
    <MindmapContext.Provider
      value={{
        readOnly,
        forcedReadOnly: readOnly || forcedReadOnly,
        setForcedReadOnly,
      }}
    >
      {children}
    </MindmapContext.Provider>
  )
}

export const useMindmapContext = () => {
  const context = useContext(MindmapContext)

  if (!context) {
    throw new Error('useMindmapContext must be used within MindmapProvider')
  }

  return context
}
