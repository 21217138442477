import { ApiClient } from '../services/api-client'
import { CandidateService } from '../services/candidate.service'
import { CompanyService } from '../services/company.service'
import { FileUploadService } from '../services/file-upload.service'
import { ProjectService } from '../services/project.service'
import { UserService } from '../services/user.service'
import { WorkflowService } from '../services/workflow.service'

export const useApiClient = () => {
  const config = {
    baseURL: process.env.REACT_APP_API_URL ?? '',
  }

  const apiClient = new ApiClient(config)

  return {
    candidatesService: new CandidateService(apiClient),
    companiesService: new CompanyService(apiClient),
    filesService: new FileUploadService(apiClient),
    projectsService: new ProjectService(apiClient),
    usersService: new UserService(apiClient),
    workflowsService: new WorkflowService(apiClient),
  }
}
