import { AxiosProgressEvent } from 'axios'
import { useRef, useState } from 'react'
import { Badge, Button, ListGroup, ListGroupItem, Row } from 'react-bootstrap'
import { useUploadFile } from '../hooks/queries/use-files'
import Dropzone from 'react-dropzone'

interface ProgressInfo {
  fileName: string
  percentage: number
}

const CV_MISSING = '/projectfiles/cv-missing.png'
const CV_EXISTS = '/projectfiles/cv-exists.png'

export const FilesUpload: React.FC<{
  onUploadsComplete?: (fileCount: number) => void
}> = ({ onUploadsComplete }) => {
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null)
  const [progressInfos, setProgressInfos] = useState<Array<ProgressInfo>>([])
  const [message, setMessage] = useState<Array<string>>([])
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const progressInfosRef = useRef<any>(null)

  const { mutateAsync: uploadFile } = useUploadFile()

  const selectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    let images: Array<string> = []
    let files = event.target.files

    if (files) {
      for (let i = 0; i < files.length; i++) {
        images.push(URL.createObjectURL(files[i]))
      }

      setSelectedFiles(files)
      setProgressInfos([])
      setMessage([])
    }
  }

  const upload = (idx: number, file: File, fileType: string) => {
    let _progressInfos = [...progressInfosRef.current]
    return uploadFile({
      file,
      fileType,
      onUploadProgress: (event: AxiosProgressEvent) => {
        _progressInfos[idx].percentage = event.total
          ? Math.round((100 * event.loaded) / event.total)
          : 0
        setProgressInfos(_progressInfos)
      },
    })
      .then(() => {
        setMessage((prevMessage) => [
          ...prevMessage,
          file.name + ': Successful!',
        ])
      })
      .catch((err: any) => {
        _progressInfos[idx].percentage = 0
        setProgressInfos(_progressInfos)

        let msg = file.name + ': Failed!'
        if (err.response && err.response.data && err.response.data.message) {
          msg += ' ' + err.response.data.message
        }

        setMessage((prevMessage) => [...prevMessage, msg])
      })
  }

  const uploadFiles = async () => {
    if (!filesToUpload.length) return
    let _progressInfos = filesToUpload.map((file) => ({
      percentage: 0,
      fileName: file.name,
    }))

    progressInfosRef.current = _progressInfos

    const uploadPromises = filesToUpload.map((file, i) => upload(i, file, 'CV'))
    try {
      await Promise.all(uploadPromises)
      // Notify parent that uploads are complete
      onUploadsComplete?.(filesToUpload.length)
    } catch (error) {
      console.error('Upload failed:', error)
    }

    setMessage([])

    setMessage([])
  }

  return (
    <div>
      <div className="my-3 d-flex">
        <Dropzone
          multiple={true}
          onDropAccepted={(files) => setFilesToUpload(files)}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone-container">
              <input {...getInputProps()} />
              <img
                src={filesToUpload.length !== 0 ? CV_EXISTS : CV_MISSING}
                alt={'files to upload area'}
                className="rounded"
              />
              {/*  {showLoading && (
                    <div className="uploading-text">Uploading..</div>
                  )} */}
            </div>
          )}
        </Dropzone>
        <div className="ms-2 d-flex flex-column justify-content-evenly">
          {filesToUpload.length === 0 && (
            <Row className="d-flex justify-content-center">
              <div className="d-flex align-items-center gap-2">
                <small className="text-secondary">
                  Välj CV:er för att ladda upp
                </small>
              </div>
            </Row>
          )}
          {filesToUpload.length > 0 && (
            <div className="d-flex align-items-center gap-2">
              <small className="text-secondary">
                {filesToUpload.length} fil(er) valda
              </small>
            </div>
          )}
          <div className="d-flex justify-content-end"></div>
          <div>
            <Button
              variant="success"
              disabled={!filesToUpload.length}
              onClick={uploadFiles}
            >
              Upload
            </Button>
          </div>
        </div>
      </div>
      <div className="d-flex gap-2 flex-wrap">
        {progressInfos &&
          message.length === 0 &&
          progressInfos.length > 0 &&
          progressInfos.map((progressInfo: ProgressInfo, index: number) => (
            <Badge bg="info" key={index}>
              {progressInfo.fileName} - {progressInfo.percentage}%
            </Badge>
          ))}
      </div>
      {message.length > 0 && (
        <ListGroup>
          {message.map((item, i) => {
            return <ListGroupItem key={i}>{item}</ListGroupItem>
          })}
        </ListGroup>
      )}
    </div>
  )
}
