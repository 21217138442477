import { UpdateWorkflowTaskAction, Workflow } from '../types/workflow'
import { ApiClient } from './api-client'

export class WorkflowService {
  private basePath: string

  constructor(private apiClient: ApiClient) {
    this.basePath = apiClient.getBaseUrl('workflow')
  }

  async getWorkflow(id: string): Promise<Workflow> {
    return this.apiClient.get<Workflow>(`${this.basePath}/workflow/${id}`)
  }

  async updateWorkflowStatus(
    updateWorkflowTaskACtion: UpdateWorkflowTaskAction
  ): Promise<Workflow> {
    return this.apiClient.patch<Workflow>(
      `${this.basePath}/workflow/${updateWorkflowTaskACtion.workflowId}`,
      { ...updateWorkflowTaskACtion }
    )
  }
}
