// src/auth/auth-provider.tsx
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import React, { createContext, useContext, useEffect } from 'react'
import { useAuth as useOidcAuth } from 'react-oidc-context'
import { AuthContextType } from '../types/auth'

const AuthContext = createContext<AuthContextType | null>(null)

interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const oidcAuth = useOidcAuth()

  // Debugging
  useEffect(() => {
    if (!oidcAuth || !oidcAuth.error) return
    console.error('oidcAuth', oidcAuth.error)
  }, [oidcAuth])

  const { data: me, isLoading } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      if (!oidcAuth.isAuthenticated) return null

      const response = await axios.get('/api/user/me', {
        headers: {
          Authorization: `Bearer ${oidcAuth.user?.access_token}`,
        },
      })
      return response.data
    },
    enabled: oidcAuth.isAuthenticated,
  })

  return (
    <AuthContext.Provider
      value={{
        user: oidcAuth.user ?? null,
        me: me ?? null,
        isAuthenticated: oidcAuth.isAuthenticated,
        isLoading: oidcAuth.isLoading || isLoading,
        login: () => oidcAuth.signinRedirect({}),
        logout: () =>
          oidcAuth.signoutRedirect({
            post_logout_redirect_uri: window.location.origin,
          }),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) throw new Error('useAuth must be used within AuthProvider')
  return context
}
